import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import AFNavbarBrand from 'components/common/af-navbar-brand';

const propTypes = {
  homeTarget: PropTypes.string.isRequired,
  currentLocation: PropTypes.string.isRequired,
};

function BrandIcon({ homeTarget, currentLocation }) {
  const handleIconClick = () => {
    if (homeTarget === currentLocation) {
      window.location.reload();
    }
  };

  return (
    <Link to={homeTarget} onClick={handleIconClick}>
      <AFNavbarBrand />
    </Link>
  ); 
}

BrandIcon.propTypes = propTypes;
export default BrandIcon;
