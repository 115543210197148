import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ModalContext from '../Modal/ModalContext';
import './ModalHeader.css';

const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
};

const defaultProps = {
  children: undefined,
  className: undefined,
  tag: 'h3',
};

function ModalHeader(props) {
  const { children, className, tag: Tag, ...other } = props;

  const { onToggle } = useContext(ModalContext);

  const handleToggle = (e) => {
    onToggle();
  };

  const classes = classNames(className, 'apollo-modal-header');

  if (onToggle) {
    return (
      <div {...other} className={classes}>
        <Tag className={classNames('apollo-modal-title')}>{children}</Tag>
        <button
          area-label="Close"
          className={classNames('apollo-close')}
          onClick={handleToggle}
          type="button"
        />
      </div>
    );
  }

  return (
    <div {...other} className={classes}>
      <Tag className={classNames('apollo-modal-title')}>{children}</Tag>
    </div>
  );
}

ModalHeader.propTypes = propTypes;
ModalHeader.defaultProps = defaultProps;

export default ModalHeader;
