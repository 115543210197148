import React, { useState, forwardRef } from 'react';
import classNames from 'classnames';
import ReactDatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import calendarSvg from './icons/calendar.svg';
import styled from 'styled-components';
import 'react-datepicker/dist/react-datepicker.css';

const propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  date: PropTypes.instanceOf(Date),
  name: PropTypes.string,
  onDateChange: PropTypes.func.isRequired,
  onEmptyDate: PropTypes.func,
  onBlur: PropTypes.func,
  placeholderText: PropTypes.string,
  startDate: PropTypes.bool,
  dateFormat: PropTypes.string,
  showWeekNumbers: PropTypes.bool,
  disabledKeyboardNavigation: PropTypes.bool,
  minDate: PropTypes.instanceOf(Date),
  inline: PropTypes.bool,
  showCalenderIcon: PropTypes.bool,
};

const defaultProps = {
  className: undefined,
  disabled: false,
  date: new Date(),
  name: undefined,
  onEmptyDate: undefined,
  onBlur: undefined,
  placeholderText: undefined,
  startDate: false,
  dateFormat: undefined,
  showWeekNumbers: false,
  disabledKeyboardNavigation: false,
  minDate: undefined,
  inline: false,
  locale: undefined,
  showCalenderIcon: true,
};

const InputWrapper = styled.div`
  position: relative;

  & input {
    border: 1px solid var(--dark-gray, darkgray);
    border-radius: 6px;
    font-family: inherit;
    font-size: 16px;
    height: 44px;
    margin: 0;
    padding: 0 1rem;
    width: 100%;
    outline: none;
    :hover {
      border: 1px solid #6593ad;
    }
    :focus {
      border: 1px solid #6593ad;
    }
  }

  & img {
    position: absolute;
    right: 9px;
    top: 6px;
    cursor: pointer;
  }
`;

const CustomInput = forwardRef(
  (
    { value, onClick, onChange, onFocus, placeholderText, showCalenderIcon },
    ref
  ) => {
    return (
      <InputWrapper>
        <input
          value={value}
          onClick={onClick}
          onChange={onChange}
          onFocus={onFocus}
          placeholder={placeholderText ? placeholderText : 'Set date'}
          ref={ref}
        />
        {showCalenderIcon && (
          <img
            src={calendarSvg}
            alt="calendar"
            className={'calendar-icon'}
            onClick={onClick}
          />
        )}
      </InputWrapper>
    );
  }
);

const ReactDatePickerWrapper = styled.div`
  .react-datepicker__header {
    background: white;
  }
  .react-datepicker__day--keyboard-selected {
    background: unset;
    color: black;
  }
  .react-datepicker-popper {
    z-index: 999;
  }
`;

function DatePicker({
  id,
  name,
  className,
  disabled,
  date,
  onDateChange,
  onEmptyDate,
  onBlur,
  placeholderText,
  dateFormat,
  startDate,
  showWeekNumbers,
  disabledKeyboardNavigation,
  minDate,
  inline,
  locale,
  showCalenderIcon,
}) {
  const [selectedStartDate, setSelectedStartDate] = useState(
    date ? new Date(date) : !startDate ? '' : new Date()
  );
  const classes = classNames(className, 'date-picker');

  const handleDateChangeRaw = (value) => {
    if (value && value.length === 8 && !value.includes('-')) {
      const dateString =
        value.substring(0, 4) +
        '-' +
        value.substring(4, 6) +
        '-' +
        value.substring(6, 8);
      const date = new Date(dateString);
      handleDateChange(date);
    } else if (value && value.length === 10 && value.includes('-')) {
      const date = new Date(value);
      handleDateChange(date);
    }
  };

  const handleDateChange = (date) => {
    setSelectedStartDate(date);

    if (date && onDateChange) {
      onDateChange(
        format(date, !dateFormat ? "yyyy-MM-dd'T'HH:mm:ss'Z'" : dateFormat)
      );
    } else if (onEmptyDate) {
      onEmptyDate(null);
    }
  };

  const handleBlur = (e) => {
    if (onBlur) {
      onBlur(e);
    }
  };

  return (
    <ReactDatePickerWrapper className={classes}>
      <ReactDatePicker
        id={id}
        name={name}
        disabled={disabled}
        selected={selectedStartDate}
        onChange={handleDateChange}
        onBlur={handleBlur}
        dateFormat="yyyy-MM-dd"
        customInput={
          <CustomInput
            placeholderText={placeholderText}
            showCalenderIcon={showCalenderIcon}
          />
        }
        onChangeRaw={(event) => handleDateChangeRaw(event.target.value)}
        showWeekNumbers={showWeekNumbers}
        disabledKeyboardNavigation={disabledKeyboardNavigation}
        minDate={new Date(minDate)}
        inline={inline}
        locale={locale}
      />
    </ReactDatePickerWrapper>
  );
}

DatePicker.propTypes = propTypes;
DatePicker.defaultProps = defaultProps;

export default DatePicker;
