class Validation {
  static Email = {
    allowedEmailAndDomain: (field, afDomains) => {
      const isValid = false;
      if (!!field && field.length > 0) {
        const containsAfDomain = afDomains.some(domain => field.trim().endsWith(domain));
        return Validation.Email.email(field) && !containsAfDomain;
      }
      return isValid;
    },
    email: (field) => {
      const validPattern = /^[\w.-]+@[a-zA-Z0-9][\w.-]*[a-zA-Z0-9]\.[a-zA-Z][a-zA-Z.]*[a-zA-Z]$/gm;
        const swedishCharPattern = /[åäö]/gm;

        const containsSwedishChars = swedishCharPattern.test(field.toLowerCase());
        const spacesInMiddle = field.includes(' ');

        return validPattern.test(field.toLowerCase())
            && !containsSwedishChars
            && field.indexOf('.@') <= 0
            && !spacesInMiddle;
    },
  }
  static Name = {
    name: (value) => {
      const pattern = /[`~!@#$%^&*()_|+=?;:'",.<>{}/¤1234567890]/g;
      return !pattern.test(value);
    }

  }
}

export default Validation;
