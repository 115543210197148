import React from 'react';
import Recaptcha from 'react-google-recaptcha';
import PropTypes from 'prop-types';

const propTypes = {
  onCaptchaVerified: PropTypes.func.isRequired,
  siteKey: PropTypes.string.isRequired,
};

const GoogleRecaptcha = (props) => {
  const {
    siteKey,
    onCaptchaVerified
  } = props;

  return (
    <div>
      <Recaptcha
        sitekey={siteKey}
        onChange={onCaptchaVerified}
      />
    </div>
  );
};

GoogleRecaptcha.propTypes = propTypes;

export default GoogleRecaptcha;
