import { connect } from 'react-redux';
import { hideModal } from 'redux/actions/ui/modal';
import ValidationModal from './validation-modal';

const mapDispatchToProps = dispatch => ({
  hideModal: () => dispatch(hideModal()),
});

export default connect(
  null,
  mapDispatchToProps,
)(ValidationModal);
