import React from 'react';
import AppRouter from './app-router';
import ErrorBoundary from './components/vendor/sentry';
import { HelmetProvider } from 'react-helmet-async';
import Modal from './components/modal';

export function App() {
  return (
    <HelmetProvider>
      <ErrorBoundary disabled>
        <AppRouter />
        <Modal/>
      </ErrorBoundary>
    </HelmetProvider>
  );
}
