import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PhoneInput2 from 'react-phone-input-2';
import parsePhoneNumber from 'libphonenumber-js';
import styled from 'styled-components';
import './phone-input2.css';

const propTypes = {
  id: PropTypes.string,
  countryCode: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  onValidChange: PropTypes.func,
};

const defaultProps = {
  onValidChange: () => {},
  countryCode: null,
  id: undefined,
  placeholder: '',
  value: undefined,
  disabled: false,
};

const CustomInput = styled.input`
  max-width: 370px;
  width: 100%;
  height: 49.33px;
  font-size: 16px;
  border: 1px solid var(--dark-gray,#a9a9a9);
  border-left: unset;
  border-radius: 0 6px 6px 0;
  background-color: #fff;
  outline: none;

  :disabled {
    background-color: #f5f5f5;
  }
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 40px auto;
`;

export const PhoneValidMsg = {
  invalid: 'Invalid',
  required: 'Required',
  valid: 'Valid',
  removeZero: 'Remove the first zero in the number'
};

const PhoneInput = (props) => {
  const {
    id,
    countryCode,
    placeholder,
    value,
    disabled,
    onChange,
    onValidChange
  } = props;

  useEffect(() => {
    onInput(value);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const [currentCountry, setCountry] = useState(null);
  const onInput = (input) => {
    const reg = /^[0-9\b]+$/;
    if (typeof input === 'string') {
      let onlyNumbers = input.replace('+', '').replace(/ /g, '').replace(/[^0-9.]/g, '');
      if (onlyNumbers && currentCountry && onlyNumbers.length === currentCountry.format.replace(/[^/.]/g, '').length) {
        const firstDigit = onlyNumbers.substr(currentCountry.countryCode.length, 1);
        onlyNumbers = firstDigit === '0' ? onlyNumbers.replace('0', '') : onlyNumbers;
      }
      if ((onlyNumbers === '' || reg.test(onlyNumbers)) && onlyNumbers !== null) {
        onChange(onlyNumbers);
      }
      const parsedPhoneNumber = parsePhoneNumber(`+${onlyNumbers}`);
      if (!onlyNumbers) {
        onValidChange(PhoneValidMsg.required);
      } else if (parsedPhoneNumber && parsedPhoneNumber.isValid()) {
        onValidChange(PhoneValidMsg.valid);
      } else {
        onValidChange(PhoneValidMsg.invalid);
      }
    }
  };

  const maskValue = (val) => {
    const temp = val ? val.replace('+', '').replace(/ /g, '') : '';
    if (currentCountry && val && val.length > currentCountry.dialCode.length && val.substr(0, currentCountry.dialCode.length) !== currentCountry.dialCode) {
      return '';
    }
    const v = temp ? [...temp] : [];
    const mask = currentCountry && [...currentCountry.format.replace('+', '')];

    let index = 0;
    let valIndex = 0;
    let retVal = '';
    let lengthOfVal = v.length;
    while (lengthOfVal > 0 && mask) {
      if (index >= mask.length || mask[index] === '.') {
        retVal += v[valIndex];
        lengthOfVal -= 1;
        index += 1;
        valIndex += 1;
        if (lengthOfVal === 0) break;
      }
      if (mask[index] !== '.' && index <= (mask.length - 1)) {
        retVal += mask[index];
        index += 1;
      }
    }
    return val ? `+${retVal.trim()}` : '';
  };

  return (
    <Wrapper>
      <PhoneInput2
        id={id}
        disabled={disabled}
        enableAreaCodes
        isValid={(v, country) => {
          if (country !== currentCountry) {
            setCountry(country);
            onChange(v);
          }
        }}
        disableSearchIcon
        enableSearch
        masks={{ se: '.. ... .. ..', ph: '... .......', xk: '.. ... ...' }}
        containerClass="phone2-container"
        inputClass={id ? `phone2-input ${id}-input` : "phone2-input" }
        searchClass={id ? `phone2-search ${id}-search` : "phone2-search"}
        buttonClass={id ? `phone2-dropdown-btn ${id}-dropdown-btn` : "phone2-dropdown-btn"}
        value={value || ''}
        country={countryCode}
        onChange={phone => onChange(phone)}
      />
      <CustomInput type="tel" disabled={disabled} placeholder={placeholder} value={maskValue(value)} onChange={e => onInput(e.target.value)} />
    </Wrapper>
  );
};

PhoneInput.propTypes = propTypes;
PhoneInput.defaultProps = defaultProps;

export default PhoneInput;
