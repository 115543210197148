import ReactGA from 'react-ga';

const options = {};

const trackPage = (page) => {
  ReactGA.set({
    page,
    ...options
  });
  ReactGA.pageview(page);
};

let currentPage = '';

const googleAnalyticsMiddleware = store => next => (action) => { // eslint-disable-line no-unused-vars
  if (action.type === '@@router/LOCATION_CHANGE') {
    const { location } = action.payload;
    const nextPage = `${location.pathname}${location.search}`;
    if (currentPage !== nextPage) {
      currentPage = nextPage;
      trackPage(nextPage);
    }
  }
  return next(action);
};

export default googleAnalyticsMiddleware;
