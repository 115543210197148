import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './column.css';

const columnPropTypes = PropTypes.oneOfType([
  PropTypes.bool,
  PropTypes.number,
  PropTypes.string,
  PropTypes.shape({
    size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    offset: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
]);

const propTypes = {
  className: PropTypes.string,
  mobile: columnPropTypes,
  tablet: columnPropTypes,
  desktop: columnPropTypes,
  largeDesktop: columnPropTypes,
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
};

const defaultProps = {
  className: null,
  mobile: 12,
  tablet: 12,
  desktop: 12,
  largeDesktop: 12,
  tag: 'div',
};

const Column = (props) => {
  const { className, tag: Tag, ...other } = props;

  const columnClasses = [];
  const columnSizes = ['mobile', 'tablet', 'desktop', 'largeDesktop'];
  const columnSizeNames = {
    mobile: 'mobile',
    tablet: 'tablet',
    desktop: 'desktop',
    largeDesktop: 'large-desktop',
  };
  columnSizes.forEach((columnSize) => {
    const columnProp = props[columnSize];
    delete other[columnSize];
    if (!columnProp && columnProp !== '') {
      return;
    }
    if (typeof columnProp === 'object') {
      columnClasses.push(
        classNames(
          `column-${columnSizeNames[columnSize]}-${columnProp.size}`,
          columnProp.offset
            ? `column-offset-${columnSizeNames[columnSize]}-${columnProp.offset}`
            : `column-offset-${columnSizeNames[columnSize]}-0`
        )
      );
    } else {
      const columnClass = `column-${columnSizeNames[columnSize]}-${columnProp}`;
      columnClasses.push(columnClass);
    }
  });

  const classes = classNames(className, 'column', columnClasses);

  return <Tag {...other} className={classes} />;
};

Column.propTypes = propTypes;
Column.defaultProps = defaultProps;

export default Column;
