import axios from 'axios';
import * as actions from 'redux/actions/api/country';

const BASE_URL = process.env.REACT_APP_ANDROMEDA_REGISTER_BASEURL;

export const fetchCountries = languageCode => (dispatch) => {
  dispatch(actions.getCountriesRequest());

  const config = {
    headers: {
      Accept: 'application/json',
      Pragma: 'no-cache',
      'Cache-Control': 'no-cache'
    }
  };

  let code = 'en';

  if (languageCode !== undefined && languageCode !== '') {
    code = languageCode;
  }

  axios.get(`${BASE_URL}/location/countries/${code}`, config)
    .then((response) => {
      dispatch(actions.getCountriesSuccess(response.data));
    })
    .catch((error) => {
      dispatch(actions.getCountriesFailure(error));
    });
};
