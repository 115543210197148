export const actionTypes = {
  REGISTER_PARTNER_REQUEST: 'REGISTER_PARTNER_REQUEST',
  REGISTER_PARTNER_SUCCESS: 'REGISTER_PARTNER_SUCCESS',
  REGISTER_PARTNER_FAILURE: 'REGISTER_PARTNER_FAILURE',

  REGISTER_FUTURE_REQUEST: 'REGISTER_FUTURE_REQUEST',
  REGISTER_FUTURE_SUCCESS: 'REGISTER_FUTURE_SUCCESS',
  REGISTER_FUTURE_FAILURE: 'REGISTER_FUTURE_FAILURE',

  VALIDATE_EMAIL_REQUEST: 'VALIDATE_EMAIL_REQUEST',
  VALIDATE_EMAIL_SUCCESS: 'VALIDATE_EMAIL_SUCCESS',
  VALIDATE_EMAIL_FAILURE: 'VALIDATE_EMAIL_FAILURE',

  GET_ALL_COMPANY_DOMAINS_REQUEST: 'GET_ALL_COMPANY_DOMAINS_REQUEST',
  GET_ALL_COMPANY_DOMAINS_SUCCESS: 'GET_ALL_COMPANY_DOMAINS_SUCCESS',
  GET_ALL_COMPANY_DOMAINS_FAILURE: 'GET_ALL_COMPANY_DOMAINS_FAILURE',
};

export const registerPartnerRequest = () => ({
  type: actionTypes.REGISTER_PARTNER_REQUEST,
});

export const registerPartnerSuccess = (data) => ({
  type: actionTypes.REGISTER_PARTNER_SUCCESS,
  payload: data,
});

export const registerPartnerFailure = (error) => ({
  type: actionTypes.REGISTER_PARTNER_FAILURE,
  payload: error,
});

export const registerFutureRequest = () => ({
  type: actionTypes.REGISTER_FUTURE_REQUEST,
});

export const registerFutureSuccess = (data) => ({
  type: actionTypes.REGISTER_FUTURE_SUCCESS,
  payload: data,
});

export const registerFutureFailure = (error) => ({
  type: actionTypes.REGISTER_FUTURE_FAILURE,
  payload: error,
});

export const getAllCompanyDomainsRequest = () => ({
  type: actionTypes.GET_ALL_COMPANY_DOMAINS_REQUEST,
});

export const getAllCompanyDomainsSuccess = (data) => ({
  type: actionTypes.GET_ALL_COMPANY_DOMAINS_SUCCESS,
  payload: data,
});

export const getAllCompanyDomainsFailure = (error) => ({
  type: actionTypes.GET_ALL_COMPANY_DOMAINS_FAILURE,
  payload: error,
});
