export const actionTypes = {
  SHOW_MODAL: 'SHOW_MODAL',
  HIDE_MODAL: 'HIDE_MODAL',
};

export const showModal = (type, props) => ({
  type: actionTypes.SHOW_MODAL,
  payload: {
    type,
    props,
  }
});

export const hideModal = () => ({
  type: actionTypes.HIDE_MODAL,
});
