import React from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Container,
  Navbar,
  NavbarBrand
} from 'components/apollo';
import { Row, Column } from 'components/grid';
import classNames from 'classnames';
import BasicAlert from 'components/common/basic-alert';
import ReactGA from 'react-ga';
import moment from 'moment';
import RegisterPersonalInfoForm from '../../components/register-personal-info-form';
import RegisterFutureInfoForm from '../../components/register-future-info-form';
import RegisterAccountInfoForm from '../../components/register-account-info-form';
import RegisterFooter from '../../components/register-footer';
import RegisterSuccess from '../../components/register-future-success';
import RegisterError from '../../components/register-error';
import Constants from '../../common/constans';
import Validation from '../../common/validation';
import './register-future.css';
import PAGES from '../../utils/ga-constants';
import iconImage from './AFRY-Logotype-Horizontal_White.svg'

const propTypes = {
  register: PropTypes.func.isRequired,
  success: PropTypes.bool.isRequired,
  azureAd: PropTypes.bool.isRequired,
};

class RegisterFuture extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      personal: {
        birthday: '',
        homeCountryId: '',
        selectedCivic: Validation.civicLastDigits.SE.locale,
        selectedLanguage: 'sv',
        selectedCountry: 'SE',
        header: 'Future',
        phoneMobile: '',
        streetAddress: '',
        zipCode: '',
        careOf: '',
        phoneNumberValid: 'required',
      },
      future: {
        graduationYear: moment().year()
      },
      account: {},
      privacyStatement: false,
      captchaCompleted: false,
      currentStep: 0,
      showErrors: false,
      virtualPage: {
        info: undefined,
        account: undefined
      }
    };

    this.steps = [
      RegisterPersonalInfoForm,
      RegisterFutureInfoForm,
      RegisterAccountInfoForm
    ];
  }

  componentDidMount() {
    const { languageCode } = this.props.params;

    this.setState({
      account: {
        languageCode
      }
    });
  }

  handleInputChange = (name, value) => {
    const propertyName = name;
    const {
      currentStep
    } = this.state;

    if (currentStep === 0) {
      const {
        personal
      } = this.state;
      if (name === 'selectedCivic') {
        const {
          birthday
        } = personal;

        if (value === 'SE') {
          this.setState(prevState => ({
            personal: {
              ...prevState.personal,
              homeCountryId: Constants.swedenCountryId,
              civicBirthDate: Validation.civicLastDigits[value].dateFormat(birthday),
              [propertyName]: value,
              selectedCivic: value
            }
          }));
        } else if (value === 'NO') {
          this.setState(prevState => ({
            personal: {
              ...prevState.personal,
              homeCountryId: Constants.norwayCountryId,
              civicBirthDate: Validation.civicLastDigits[value].dateFormat(birthday),
              [propertyName]: value,
              selectedCivic: value,
              selectedCountry: value,
            }
          }));
        } else if (value === 'DK') {
          this.setState(prevState => ({
            personal: {
              ...prevState.personal,
              homeCountryId: Constants.denmarkCountryId,
              civicBirthDate: Validation.civicLastDigits[value].dateFormat(birthday),
              [propertyName]: value,
              selectedCivic: value,
              selectedCountry: value,
            }
          }));
        } else if (value === 'FI') {
          this.setState(prevState => ({
            personal: {
              ...prevState.personal,
              homeCountryId: Constants.finlandCountryId,
              civicBirthDate: Validation.civicLastDigits[value].dateFormat(birthday),
              [propertyName]: value,
              selectedCivic: value,
              selectedCountry: value,
            }
          }));
        } else if (value === 'otherCountry') {
          this.setState(prevState => ({
            personal: {
              ...prevState.personal,
              homeCountryId: Constants.swedenCountryId,
              civicBirthDate: Validation.civicLastDigits[value].dateFormat(birthday),
              [propertyName]: value,
              selectedCivic: 'otherCountry',
              selectedCountry: 'SE',
            }
          }));
        }
      } else if (name === 'phoneNumberValid') {
        if (value !== personal.phoneNumberValid) {
          this.setState(prevState => ({
            personal: { ...prevState.personal, phoneNumberValid: value }
          }));
        }
      } else {
        this.setState(prevState => ({
          personal: { ...prevState.personal, [propertyName]: value }
        }));
      }
    } else if (currentStep === 1) {
      this.setState(prevState => ({
        future: { ...prevState.future, [propertyName]: value }
      }));
    } else if (currentStep === 2) {
      this.setState(prevState => ({
        account: { ...prevState.account, [propertyName]: value }
      }));
    }
  }

  handleStepValid = (valid) => {
    const {
      currentStep
    } = this.state;

    if (currentStep === 0) {
      this.setState({
        personalValid: valid
      });
    } else if (currentStep === 1) {
      this.setState({
        futureValid: valid
      });
    } else if (currentStep === 2) {
      this.setState({
        accountValid: valid
      });
    }
  }

  handleNextClick = () => {
    const {
      currentStep,
      futureValid,
    } = this.state;

    const {
      personal
    } = this.state;

    const {
      firstName,
      lastName,
      birthday,
      phoneNumberValid,
      zipCode,
      streetAddress,
      homeCountryId,
      linkedin,
      civicBirthDate,
      civicLastDigits,
      selectedCivic,
      geoLocationKey
    } = personal;

    const personValid = Validation.Person.firstName(firstName)
      && Validation.Person.lastName(lastName)
      && Validation.Person.birthDay(birthday)
      && phoneNumberValid === 'Valid'
      && Validation.Person.geoLocationKey(geoLocationKey)
      && Validation.Person.zipCode(zipCode)
      && Validation.Person.streetAddress(streetAddress)
      && Validation.Person.country(homeCountryId)
      && Validation.Person.linkedin(linkedin)
      && Validation.Person.civicRegNumberLast(civicLastDigits, selectedCivic)
      && Validation.Person.civicRegNumberCheckSum(civicBirthDate, civicLastDigits, selectedCivic);

    if (currentStep === 0 && !personValid) {
      console.log(phoneNumberValid);
      this.setState({
        showErrors: true
      });
    } else if (currentStep === 1 && !futureValid) {
      this.setState({
        showErrors: true
      });
    } else {
      this.setState({
        currentStep: currentStep + 1,
        showErrors: false
      }, this.setVirtualPage);
    }
  }

  setVirtualPage = () => {
    const {
      currentStep,
      virtualPage: {
        info,
        account
      }
    } = this.state;

    switch (currentStep) {
      case 1:
        if (info === undefined) {
          ReactGA.ga('send', 'pageview', `${window.location.pathname}${PAGES.INFO}`);
        }
        this.setState(prevState => ({
          virtualPage: {
            ...prevState.virtualPage,
            info: true
          }
        }));
        break;
      case 2:
        if (account === undefined) {
          ReactGA.ga('send', 'pageview', `${window.location.pathname}${PAGES.ACCOUNT}`);
        }
        this.setState(prevState => ({
          virtualPage: {
            ...prevState.virtualPage,
            account: true
          }
        }));
        break;
      default:
        break;
    }
  };

  handleBackClick = () => {
    const {
      currentStep
    } = this.state;

    this.setState({
      currentStep: currentStep - 1,
      showErrors: false
    });
  }

  handleSubmit = () => {
    const {
      accountValid
    } = this.state;

    if (!accountValid) {
      this.setState({
        showErrors: true
      });
    } else {
      const { register } = this.props;
      register(this.state);
    }
  }

  handleBirthdayChange = (obj) => {
    const {
      birthday,
      civicBirthDate,
    } = obj;

    const {
      personal
    } = this.state;

    this.setState({
      personal: {
        ...personal,
        birthday,
        civicBirthDate,
      }
    });
  }

  render() {
    const {
      currentStep,
      personal,
      future,
      account,
      showErrors
    } = this.state;

    const {
      success,
      azureAd
    } = this.props;

    const showBack = currentStep !== 0;
    const showNext = currentStep < this.steps.length - 1;

    const Step = this.steps[currentStep];
    const data = [personal, future, account];

    const contentCols = {
      mobile: {
        size: 12,
        offset: 0
      },
      tablet: {
        size: 12,
        offset: 0
      },
      desktop: {
        size: 8,
        offset: 2
      },
      largeDesktop: {
        size: 8,
        offset: 2
      }
    };

    const classes = classNames('register-future');
    const contentClasses = classNames('register-future-content');

    return (
      <div className={classes}>
        <Navbar
          dark
          fixed="top"
          expand="large"
        >
          <Container>
            <NavbarBrand icon={iconImage} />
          </Container>
        </Navbar>
        <Container className={'register-mobile-container'}>
          <Row>
            <Column
              mobile={contentCols.mobile}
              tablet={contentCols.tablet}
              desktop={contentCols.desktop}
              largeDesktop={contentCols.largeDesktop}
            >
              <div className={contentClasses}>
                {success && (
                  <RegisterSuccess />
                )}
                {!success && (
                  <Step
                    data={data[currentStep]}
                    onChange={this.handleInputChange}
                    onBirthDayChange={this.handleBirthdayChange}
                    onValidChange={this.handleStepValid}
                    showErrors={showErrors}
                    registerPassword={!azureAd}
                  />
                )}
                <RegisterError />
              </div>
            </Column>
          </Row>
          {
            showErrors && (
              <Row>
                <Column mobile={contentCols.mobile} tablet="hidden" desktop="hidden" largeDesktop="hidden">
                  <BasicAlert />
                </Column>
              </Row>
            )
          }
        </Container>
        {!success && (
          <RegisterFooter
            showNext={showNext}
            showBack={showBack}
            onNext={this.handleNextClick}
            onBack={this.handleBackClick}
            onSubmit={this.handleSubmit}
          />
        )}
      </div>
    );
  }
}

RegisterFuture.propTypes = propTypes;

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}
export default withParams(RegisterFuture);
