import React from 'react';
import {
  Loader
} from 'components/apollo';
import Selects from 'react-select'
import PropTypes from 'prop-types';

const propTypes = {
  countries: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  })),
  isDisabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  selectedValue: PropTypes.string.isRequired,
  fetchCountries: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
};

const defaultProps = {
  countries: []
};

const customStyle = {
  control: (provided) => ({
    ...provided,
    border: '1px solid var(--dark-gray,#a9a9a9)',
    borderRadius: '6px',
    height: '3rem'
  })
}

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

const formatGroupLabel = data => (
  <div style={groupStyles}>
    <span>{data.label}</span>
  </div>
);

const favorites = [
  '1D833FA0-A3C7-4B58-A0D9-1B678B2E8A3E',
  'A5AABD96-DFB4-4D69-8D31-41ECD731CB18',
  'F7309C08-B332-4DC1-8F48-36E10FF7F76D',
  '92222C63-38B6-489F-9D85-034DD7CB3E3B',
  '44FAFCC3-A79B-4186-B672-6735ABD35707'
];

class CountrySelect extends React.Component {
  constructor() {
    super();

    this.state = {
      groupedOptions: [],
    }
  }

  groupCountries = (countries) => {
    const favoritesOptions = countries.filter(c => favorites.includes(c.id.toUpperCase())).map(c => ({ label: c.name, value: c.id }));
    const restOptions = countries.filter(c => !favorites.includes(c.id.toUpperCase())).map(c => ({ label: c.name, value: c.id }));
    this.setState({
      groupedOptions: [
        {
          label: 'Most common',
          options: favoritesOptions
        },
        {
          label: 'Countries',
          options: restOptions
        }
      ]
        
      
    })
  }

  componentDidMount() {
    const {
      fetchCountries,
      language,
      countries
    } = this.props;
    if (countries.length < 1) {
      fetchCountries(language);
    } else {
      this.groupCountries(countries);
    }
  }

  componentDidUpdate(prevProps) {
    const { countries } = this.props;
    if (JSON.stringify(prevProps.countries) !== JSON.stringify(countries) && countries.length > 0) {
      this.groupCountries(countries);
    }
  }

  render() {
    const {
      isDisabled,
      onChange,
      selectedValue,
      countries
    } = this.props;

    const { groupedOptions } = this.state;
    const selectedCountry = countries.find(c => c.id === selectedValue);
    const value = {};
    if (selectedCountry) {
      value.value = selectedCountry.id;
      value.label = selectedCountry.name;
    }
    if (groupedOptions.length > 0) {
      return (
        <Selects
          placeholder="Select a country"
          styles={customStyle}
          disabled={isDisabled}
          value={value}
          onChange={(data) => onChange({target: {value: data.value, name: 'homeCountryId', label: data.label}})}
          options={groupedOptions}
          formatGroupLabel={formatGroupLabel}
        />
      );
    }

    return (<Loader height={100} width={200} />);
  }
}

CountrySelect.propTypes = propTypes;
CountrySelect.defaultProps = defaultProps;

export default CountrySelect;
