import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ModalContext from './ModalContext';
import './Modal.css';

const propTypes = {
  autoFocus: PropTypes.bool,
  backdropClassName: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  dialogClassName: PropTypes.string,
  keyboard: PropTypes.bool,
  onClosed: PropTypes.func,
  onEnter: PropTypes.func,
  onExit: PropTypes.func,
  onOpened: PropTypes.func,
  onToggle: PropTypes.func,
  open: PropTypes.bool,
};

const defaultProps = {
  autoFocus: true,
  backdropClassName: undefined,
  children: undefined,
  className: undefined,
  contentClassName: undefined,
  dialogClassName: undefined,
  keyboard: false,
  onClosed: undefined,
  onEnter: undefined,
  onExit: undefined,
  onOpened: undefined,
  onToggle: undefined,
  open: false,
};

function Modal(props) {
  const {
    backdropClassName,
    children,
    className,
    contentClassName,
    dialogClassName,
    open,
    onOpened,
    onClosed,
    keyboard,
    onToggle,
    autoFocus,
    onEnter,
    onExit,
    ...other
  } = props;

  const [animationType, setAnimationType] = useState('apollo-show');
  const [isOpen, setIsOpen] = useState(open);
  const dialogRef = useRef();

  const setFocus = () => {
    if (!dialogRef.current) {
      return;
    }

    const { parentNode } = dialogRef.current;
    if (parentNode && typeof parentNode.focus === 'function') {
      parentNode.focus();
    }
  };

  const handleOpened = () => {
    setAnimationType('apollo-show');
    setIsOpen(true);
    if (onOpened) {
      onOpened();
    }
  };

  const handleClosed = () => {
    setAnimationType('apollo-hide');
  };

  const handleKeyboard = (e) => {
    if (!onToggle) {
      e.preventDefault();
      return;
    }

    if (open && keyboard && e.keyCode === 27) {
      onToggle(e);
    }
  };

  const handleAnimationEnd = () => {
    if (animationType === 'apollo-hide') {
      setIsOpen(false);
      if (onClosed) {
        onClosed();
      }
    }
  };

  // componentDidMount
  // componentWillUnmount
  useEffect(() => {
    if (onEnter) {
      onEnter();
    }

    if (open && autoFocus) {
      setFocus();
    }
    return () => {
      if (onExit) {
        onExit();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // UNSAFE_componentWillReceiveProps
  // componentDidUpdate
  useEffect(() => {
    if (open) {
      handleOpened();
      if (autoFocus) {
        setFocus();
      }
    } else {
      handleClosed();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  if (!isOpen) {
    return null;
  }

  const modalClasses = classNames(className, 'apollo-modal', animationType);

  const modalAttributes = {
    onAnimationEnd: handleAnimationEnd,
    onKeyUp: handleKeyboard,
    role: 'dialog',
    style: { display: 'block' },
    tabIndex: '-1',
  };

  const backdropClasses = classNames(
    backdropClassName,
    'apollo-modal-backdrop'
  );

  const contentClasses = classNames(contentClassName, 'apollo-modal-content');

  const dialogClasses = classNames(dialogClassName, 'apollo-modal-dialog');

  const contextValue = {
    onToggle,
  };

  return (
    <ModalContext.Provider value={contextValue}>
      <div>
        <div {...other} {...modalAttributes} className={modalClasses}>
          <div ref={dialogRef} className={dialogClasses}>
            <div className={contentClasses}>{children}</div>
          </div>
        </div>
        <div className={backdropClasses} />
      </div>
    </ModalContext.Provider>
  );
}

Modal.propTypes = propTypes;
Modal.defaultProps = defaultProps;

export default Modal;
