import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './row.css';

const propTypes = {
  className: PropTypes.string,
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
};

const defaultProps = {
  className: null,
  tag: 'section',
};

const Row = (props) => {
  const { className, tag: Tag, ...other } = props;

  const classes = classNames(className, 'row');

  return <Tag {...other} className={classes} />;
};

Row.propTypes = propTypes;
Row.defaultProps = defaultProps;

export default Row;
