import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import axios from 'axios';
import classNames from 'classnames';
import { closedDates } from 'constants';
import { Container, Navbar, NavbarBrand } from 'components/apollo';
import { Row, Column } from 'components/grid';
import { LoaderContainer, Loader, Content } from './styledComps';
import { Switch, Route } from 'react-router';
import { NotFoundContainer } from 'views/shared/not-found';
import RegisterSuccess from '../components/register-partner-success';
import RegisterPartner from '../views/register-partner';
import RegisterFuture from '../views/register-future';
import { appRoutes } from '../../../app-routes';
import './register.css';
import iconImage from './AFRY-Logotype-Horizontal_White.svg';

const parentRoutePath = appRoutes.register;

export const registerRoutes = {
  root: parentRoutePath,
  partner: `${parentRoutePath}/partner/:languageCode?`,
  future: `${parentRoutePath}/future/:languageCode?`,
};

const contentCols = {
  mobile: {
    size: 12,
    offset: 0,
  },
  tablet: {
    size: 12,
    offset: 0,
  },
  desktop: {
    size: 8,
    offset: 2,
  },
  largeDesktop: {
    size: 8,
    offset: 2,
  },
};

const classes = classNames('register');

const RegisterRouter = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [closed, setClosed] = useState(false);

  const getCurrentTime = () => {
    const config = {
      headers: {
        Accept: 'application/json',
        Pragma: 'no-cache',
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
      },
    };

    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_ANDROMEDA_REGISTER_BASEURL}/Register/CurrentTime`,
        config
      )
      .then((response) => {
        setLoading(false);
        if (
          moment
            .tz(response.data.split('.')[0], 'Europe/London')
            .isAfter(closedDates.from) &&
          moment
            .tz(response.data.split('.')[0], 'Europe/London')
            .isBefore(closedDates.to)
        ) {
          setClosed(true);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        // setCurrTime(null); //setCurrTime is not defined.
        setError(true);
      });
  };

  useEffect(() => {
    getCurrentTime();
  }, []);

  if (loading || closed || error) {
    return (
      <div className={classes}>
        <Navbar dark fixed="top" expand="large">
          <Container>
            <NavbarBrand icon={iconImage} />
          </Container>
        </Navbar>
        <Container className={'register-mobile-container'}>
          <Row>
            <Column {...contentCols}>
              <Content>
                {loading && (
                  <LoaderContainer>
                    <Loader width={25} height={25}>
                      <div className="double-bounce1"></div>
                      <div className="double-bounce2"></div>
                    </Loader>
                    <p>Fetching form...</p>
                  </LoaderContainer>
                )}
                {error && 'Unable to fetch form'}
                {closed && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `Due to system updates the registration of new Networkers (Partner and Future) will be disabled during the ${closedDates.from
                        .format('Do')
                        .replace(
                          /(\d)(st|nd|rd|th)/g,
                          '$1<sup>$2</sup>'
                        )} - ${closedDates.to
                        .format('Do of MMMM')
                        .replace(/(\d)(st|nd|rd|th)/g, '$1<sup>$2</sup>')}`,
                    }}
                  />
                )}
              </Content>
            </Column>
          </Row>
        </Container>
      </div>
    );
  }

  return (
    <Switch>
      <Route exact path={registerRoutes.partner} component={RegisterPartner} />
      <Route
        exact
        path={`${parentRoutePath}/test/:languageCode?`}
        component={RegisterSuccess}
      />
      <Route exact path={registerRoutes.future} component={RegisterFuture} />
      <Route path="*" component={NotFoundContainer} />
    </Switch>
  );
};

export default RegisterRouter;
