// For icons that are frequently imported in multiple components.
// This enables setting an icon value by a string rather than explicit import
// More info here: https://github.com/FortAwesome/react-fontawesome

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faEye,
  faSave,
  faLock,
  faArrowLeft,
  faArrowRight,
  faTimes,
  faHandPointer,
  faEnvelope,
  faCheck,
  faUserCheck,
  faClock,
  faClipboardList,
  faArrowUp,
  faQuestion,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import {
  faCircle
} from '@fortawesome/free-regular-svg-icons';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  library.add(
    // solid
    faSave,
    faLock,
    faEye,
    faArrowLeft,
    faArrowRight,
    faTimes,
    faHandPointer,
    faEnvelope,
    faCheck,
    faUserCheck,
    faClock,
    faClipboardList,
    faArrowUp,
    faQuestion,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    // regular
    faCircle
  );
};
