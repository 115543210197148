import React from 'react';
import PropTypes from 'prop-types';
import {
  Input,
  Label,
  Form,
  FormGroup,
  FormFeedback,
  FormText,
  Select,
  SelectOption
} from 'components/apollo';
import classNames from 'classnames';
import { Row, Column } from 'components/grid';
import PhoneInput, { PhoneValidMsg } from 'components/inputs/phone-input2';
import CountrySelect from 'components/selects/country-select';
import CitySelect from 'components/selects/city-select';
import moment from 'moment';
import RegisterColumns from '../../common/register-columns';
import Validation from '../../common/validation';
import './register-personal-info-form.css';
import DatePicker from 'components/common/date-picker/date-picker'
import { fetchRegion } from '../../../../redux/services/api/region/region-services';

const propTypes = {
  onChange: PropTypes.func.isRequired,
  onBirthDayChange: PropTypes.func.isRequired,
  onValidChange: PropTypes.func.isRequired,
  showErrors: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    selectedCountry: PropTypes.string,
    selectedCivic: PropTypes.string,
    selectedLanguage: PropTypes.string,
    userCountryCode: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    phoneMobile: PropTypes.string.isRequired,
    streetAddress: PropTypes.string.isRequired,
    zipCode: PropTypes.string.isRequired,
    careOf: PropTypes.string.isRequired,
    linkedin: PropTypes.string,
    geoLocationKey: PropTypes.string
  }),
  portalUrl: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
};

const defaultProps = {
  data: {
    firstName: '',
    lastName: '',
    phoneMobile: '',
    city: '',
    streetAddress: '',
    zipCode: '',
    careOf: '',
    linkedin: '',
    geoLocationKey: ''
  }
};

const civicBirthDateCountries = ['SE', 'NO', 'DK', 'FI'];

let country = ''
let region = ''

class RegisterPersonalInfoForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneCountry: props.data.selectedCountry
    };
    moment.locale(props.data.selectedCivic);
  }

  static getDerivedStateFromProps(props, state) {
    if (state.phoneCountry !== props.data.selectedCountry) {
      return { phoneCountry: props.data.selectedCountry };
    }

    return null;
  }

  handlePhoneChange = (input) => {
    this.handleFormChange('phoneMobile', input);
  }

  handlePhoneValid = (valid) => {
    this.handleFormChange('phoneNumberValid', valid);
  }

  getPhoneErrorMsg = (valid) => {
    switch (valid) {
      case PhoneValidMsg.required:
        return 'Phone mobile is required';
      case PhoneValidMsg.removeZero:
        return 'Remove the first zero from number';
      default:
        return 'Phone mobile is invalid';
    }
  }

  handleChange = async (e) => {
    const {
      name,
      value,
    } = e.target;

    if (e.target.name === 'homeCountryId') {
      country = e.target.label;
      region = '';
    }
    if (e.target.name === 'geoLocationKey') {
      this.handleFormChange('city', e.target.label);
      await fetchRegion(country, e.target.label).then((res) => region = res.data[0] !== null && res.data.length > 0 ? res.data[0].name : '');
    }

    this.handleFormChange(name, value);
  }

  handleBirthdateChange = (number) => {
    let temp = number;
    const format = moment()._locale._longDateFormat.L.split('');
    if (number.length !== format.length && format[number.length - 1] !== format[number.length]) {
      temp += format[number.length];
    }
    this.handleFormChange('birthday', temp);
  }

  handleFormChange = (name, value) => {
    const {
      onChange,
      data,
    } = this.props;

    const {
      selectedCivic
    } = data;

    if (name === 'birthday') {
      const {
        onBirthDayChange
      } = this.props;

      onBirthDayChange({
        birthday: value,
        civicBirthDate: civicBirthDateCountries.includes(selectedCivic) ? Validation.civicLastDigits[selectedCivic].dateFormat(value) : Validation.civicLastDigits.otherCountry.dateFormat(value),
      });
    } else {
      onChange(name, value);
    }
  }

  handleCivicCountryChange = (e) => {
    const {
      phoneCountry,
    } = this.state;

    const { data } = this.props;
    const { selectedCivic } = data;

    if (!phoneCountry) {
      this.setState({ phoneCountry: selectedCivic });
    }

    this.handleFormChange('selectedCivic', e.target.value);
  }

  handleCivicLastDigitsChange = (e) => {
    const {
      data
    } = this.props;

    const {
      selectedCivic
    } = data;
    const lengthLimit = e.target.value.length <= (civicBirthDateCountries.includes(selectedCivic) ? Validation.civicLastDigits[selectedCivic].digitlength : Validation.civicLastDigits.otherCountry.digitlength);

    if (lengthLimit) {
      this.handleFormChange('civicLastDigits', e.target.value);
    }
  }

  renderCivicRegNumber() {
    const {
      showErrors,
      data
    } = this.props;

    const {
      selectedCivic,
      civicLastDigits,
      civicBirthDate,
    } = data;

    const classes = classNames('civic-reg-number');
    const birthDayClass = classNames('civic-birthdate');
    const lastDigitClass = classNames('civic-last');
    const contentDivider = classNames('content-divider');

    const invalidRegnumberFormat = civicBirthDateCountries.includes(selectedCivic) ? !Validation.Person.civicRegNumberLast(civicLastDigits, selectedCivic) : !Validation.Person.civicRegNumberLast(civicLastDigits, 'otherCountry');
    const invalidChecksum = civicBirthDateCountries.includes(selectedCivic) ? !Validation.Person.civicRegNumberCheckSum(civicBirthDate, civicLastDigits, selectedCivic) : !Validation.Person.civicRegNumberCheckSum(civicBirthDate, civicLastDigits, 'otherCountry');
    const showLastDigitError = showErrors && (invalidRegnumberFormat || invalidChecksum);

    const otherCountry = 'otherCountry';

    return (
      <div className={classes}>
        <Row>
          <Column {...RegisterColumns.leftColumn}>
            <Label for="civicRegFormat">Social security number</Label>
            <FormGroup className="social-security-number-control social-security-number-country-input">
              <Select name="civicRegFormat" value={selectedCivic} onChange={this.handleCivicCountryChange}>
                <SelectOption value={Validation.civicLastDigits.SE.locale}>Sweden</SelectOption>
                <SelectOption value={Validation.civicLastDigits.NO.locale}>Norway</SelectOption>
                <SelectOption value={Validation.civicLastDigits.DK.locale}>Denmark</SelectOption>
                <SelectOption value={Validation.civicLastDigits.FI.locale}>Finland</SelectOption>
                <SelectOption value={otherCountry}>Other country</SelectOption>
              </Select>
              <FormText>Select format</FormText>
            </FormGroup>
          </Column>
          <Column {...RegisterColumns.rightColumn}>

            {civicBirthDateCountries.includes(selectedCivic) && (
              <div className="social-security-last-digits">
                <div>
                  <Input disabled className={birthDayClass} value={civicBirthDate} />
                  <FormText>{Validation.civicLastDigits[selectedCivic].format}</FormText>
                </div>
                <FormGroup className={lastDigitClass}>
                  <Input
                    name="civicLastDigits"
                    value={civicLastDigits}
                    onChange={this.handleCivicLastDigitsChange}
                  />
                  <FormText>{Validation.civicLastDigits[selectedCivic].placeholder}</FormText>
                  {showLastDigitError && (
                    <FormFeedback>Invalid Social security number</FormFeedback>
                  )}
                </FormGroup>
              </div>
            )}
          </Column>
        </Row>
        <Row>
          <Column {...RegisterColumns.fullColumn}>
            <div className={contentDivider} />
          </Column>
        </Row>
      </div>
    );
  }

  render() {

    const {
      showErrors,
      data,
      portalUrl,
    } = this.props;

    const {
      firstName,
      lastName,
      phoneMobile,
      city,
      streetAddress,
      zipCode,
      careOf,
      birthday,
      linkedin,
      homeCountryId,
      header,
      phoneNumberValid,
      selectedLanguage,
      selectedCivic,
      geoLocationKey,

    } = data;

    const {
      phoneCountry,
      callingCode,
    } = this.state;

    moment.locale(selectedLanguage);
    const classes = classNames('register-personal-info-form');
    return (
      <Form className={classes}>
        <Row>
          <Column {...RegisterColumns.fullColumn}>
            <h2>Register your {header} profile</h2>
            <p>This is the first step to register your AFRY Network Profile. Please fill in your personal information below. Each AFRY Network Profile has to be registered with an individual user account and a personal e-mail as username.</p>
            <p>Please note that all consultants, due to GDPR, need to register their profile on their own. <br /><br /> Hence, you and only you are allowed to register your own profile. Therefore, as a sales manager, ask your consultant to add their own profile.</p>
            <p>Are you already registered? Login <a target="_blank" rel="noopener noreferrer" href={portalUrl}>here</a>.</p>
          </Column>
        </Row>
        {header === "Partner" &&
          <Row>
            <Column className="blue-info-box" {...RegisterColumns.fullColumn} >
              <p>Please note that if you are already registered and would like to update any information
                such as company name, organization number, etc on your profile, you shall not and are not
                allowed to create a duplicated profile in our system. If you need assistance with such
                update or change, please contact{" "}
                <a href="mailto:PartnerBusinessSupport@afry.com">PartnerBusinessSupport@afry.com</a>.
              </p>
            </Column>
          </Row>
        }
        <Row>
          <Column {...RegisterColumns.fullColumn}>
            <h5>AFRY Available Assignments Policy</h5>
            <p>AFRY Available Assignments are considered AFRY’s assets and cannot be used for any other 
              purpose than those decided by AFRY. This means that a registered {header} is only allowed 
              to download or copy an Available Assignment for their own use and/or to share with their employees. 
              It is not allowed to repost or republish AFRY’s Available Assignments outside AFRY’s platform without 
              AFRY's written approval.
            </p>
          </Column>
        </Row>
        <Row>
          <Column {...RegisterColumns.leftColumn}>
            <h5>Personal details</h5>
          </Column>
        </Row>
        <Row>
          <Column {...RegisterColumns.leftColumn}>
            <FormGroup>
              <Label for="firstName">First name</Label>
              <Input name="firstName" onChange={this.handleChange} value={firstName} />
              {showErrors
                && !Validation.Person.firstName(firstName) && (<FormFeedback>First name is required</FormFeedback>)}
            </FormGroup>
          </Column>
          <Column {...RegisterColumns.rightColumn}>
            <FormGroup>
              <Label for="lastName">Last name</Label>
              <Input name="lastName" onChange={this.handleChange} value={lastName} />
              {showErrors
                && !Validation.Person.lastName(lastName) && (<FormFeedback>Last name is required</FormFeedback>)}
            </FormGroup>
          </Column>
        </Row>
        <Row>
          <Column {...RegisterColumns.leftColumn}>
            <FormGroup>
              <Label for="birthDate">Date of birth</Label>
              <DatePicker
                id='register-date-picker'
                date={birthday}
                onDateChange={this.handleBirthdateChange}
                placeholderText={selectedCivic === 'SE' ? 'YY' + Validation.civicLastDigits[selectedCivic].format : Validation.civicLastDigits[selectedCivic].format}
                dateFormat='yyyy-MM-dd'
                startDate={false}
              >
              </DatePicker>
              {showErrors
                && !Validation.Person.birthDay(birthday) && (<FormFeedback>Date of birth is required</FormFeedback>)}
            </FormGroup>
          </Column>
        </Row>
        {Validation.Person.birthDay(birthday) && (
          this.renderCivicRegNumber()
        )}
        <Row>
          <Column {...RegisterColumns.leftColumn}>
            <h5>Contact details</h5>
          </Column>
        </Row>
        <Row>
          <Column {...RegisterColumns.leftColumn}>
            <FormGroup>
              <Label for="phoneMobile">Phone mobile</Label>
              {callingCode}
              <PhoneInput
                onValidChange={this.handlePhoneValid}
                value={phoneMobile}
                countryCode={phoneCountry.toLowerCase()}
                onChange={this.handlePhoneChange}
              />
              {showErrors && phoneNumberValid !== PhoneValidMsg.valid && (<FormFeedback>{this.getPhoneErrorMsg(phoneNumberValid)}</FormFeedback>)}
            </FormGroup>
          </Column>
          <Column {...RegisterColumns.rightColumn}>
            <FormGroup>
              <div className="labels">
                <Label for="linkedin">LinkedIn</Label>
                <FormText>Optional</FormText>
              </div>
              <Input name="linkedin" onChange={this.handleChange} value={linkedin} />
              {showErrors && !Validation.Person.linkedin(linkedin) && (<FormFeedback>Not a valid linkedIn URL</FormFeedback>)}
            </FormGroup>
          </Column>
        </Row>
        <Row>
          <Column {...RegisterColumns.leftColumn}>
            <FormGroup>
              <Label for="Country">Country</Label>{showErrors
                && !Validation.Person.country(homeCountryId) && (<FormFeedback>Country is required and must be valid</FormFeedback>)}
              <CountrySelect onChange={this.handleChange} selectedValue={homeCountryId} isDisabled={false} />
              <FormText indented>Select your country of current residence</FormText>
            </FormGroup>
          </Column>
          <Column {...RegisterColumns.rightColumn}>
            <FormGroup>
              <Label for="streetAddress">City</Label>
              {showErrors
                && !Validation.Person.geoLocationKey(geoLocationKey) && (<FormFeedback>City is required</FormFeedback>)}
              {!homeCountryId && <Select disabled></Select>}
              {homeCountryId && <CitySelect onChange={this.handleChange} selectedValue={geoLocationKey} countryKey={homeCountryId} />}
              <FormText indented>Select your city of current residence</FormText>
            </FormGroup>
          </Column>
        </Row>
        <Row>
          <Column {...RegisterColumns.leftColumn}>
            <FormGroup>
              <Label for="zipCode">Zip code</Label>
              <Input name="zipCode" onChange={this.handleChange} value={zipCode} />
              {showErrors && !Validation.Person.zipCode(zipCode) && (<FormFeedback>Zip code is required</FormFeedback>)}
            </FormGroup>
          </Column>
          <Column {...RegisterColumns.rightColumn}>
            <FormGroup>
              <Label for="streetAddress">Street address</Label>
              <Input name="streetAddress" onChange={this.handleChange} value={streetAddress} />
              {showErrors
                && !Validation.Person.streetAddress(streetAddress)
                && (<FormFeedback>Street address is required</FormFeedback>)}
            </FormGroup>
          </Column>
        </Row>
        <Row>
          <Column {...RegisterColumns.leftColumn}>
            <FormGroup>
              <div className="labels">
                <Label for="careOf">C/O</Label>
                <FormText>Optional</FormText>
              </div>
              <Input name="careOf" onChange={this.handleChange} value={careOf} />
            </FormGroup>
          </Column>
          <Column {...RegisterColumns.rightColumn}>
            <FormGroup>
              <Label for="region">Region</Label>
              <Input name="region" disabled value={region} />
            </FormGroup>
          </Column>
        </Row>
      </Form>
    );
  }
}

RegisterPersonalInfoForm.defaultProps = defaultProps;
RegisterPersonalInfoForm.propTypes = propTypes;
export default RegisterPersonalInfoForm;
