import React from 'react';
import PropTypes from 'prop-types';
import './settings-tooltip.css';

const propTypes = {
  children: PropTypes.node.isRequired,
  content: PropTypes.arrayOf(PropTypes.string).isRequired
};

const SettingsTooltip = (props) => {
  const {
    children,
    content
  } = props;

  const text = content.map(x => <p key={x}>{x}</p>);

  return (
    <div className="settings-tooltip">
      {children}
      <div className="tooltip-positioner">
        <div className="tooltip-parent">
          <div className="tooltip">?
            <div className="tooltip-text">
              {text}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SettingsTooltip.propTypes = propTypes;

export default SettingsTooltip;
