import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { Container } from 'components/apollo';
import styles from './error-boundary.module.css';

const propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
};

const defaultProps = {
  children: null,
  disabled: true,
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    const { disabled } = this.props;
    if (!disabled) {
      this.setState({ hasError: true });
    }
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      const title = "'We're sorry — something's gone wrong.";
      const message = 'Our team has been notified, but click here fill out a report.';

      return (
        <div className={styles['error-boundary']}>
          <Container>
            <Helmet title="Error" />
            <p>{title}</p>
            <p>{message}</p>
          </Container>
        </div>
      );
    }

    return children;
  }
}

ErrorBoundary.propTypes = propTypes;
ErrorBoundary.defaultProps = defaultProps;

export default ErrorBoundary;
