import { actionTypes } from 'redux/actions/api/register';

const initialState = {
  success: false,
};

const registerReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.REGISTER_PARTNER_SUCCESS:
      return { ...state, success: true, error: null, data: action.payload };
    case actionTypes.REGISTER_FUTURE_SUCCESS:
      return { ...state, success: true, error: null };
    case actionTypes.REGISTER_PARTNER_FAILURE:
      return { ...state, error: action.payload.response.data.message };
    case actionTypes.REGISTER_FUTURE_FAILURE:
      return { ...state, error: action.payload.response.data.message };
    case actionTypes.GET_ALL_COMPANY_DOMAINS_SUCCESS:
      return { ...state, companydomains: action.payload };
    default:
      return state;
  }
};

export default registerReducer;
