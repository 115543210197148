import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Column } from 'components/grid';
import ReactGA from 'react-ga';
import RegisterColumns from '../../common/register-columns';
import PAGES from '../../utils/ga-constants';
import moment from 'moment';

const propTypes = {
  portalUrl: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  status: PropTypes.objectOf(PropTypes.any),
};

const RegisterPartnerSuccess = (props) => {
  useEffect(() => {
    ReactGA.ga('send', 'pageview', `${window.location.pathname}${PAGES.THANK_YOU}`);
  }, []);

  const {
    portalUrl,
    status
  } = props;

  if (status.isWaitingRelation && !status.isActiveSubcon) {
    return (
      <Row>
        <Column {...RegisterColumns.fullColumn}>
          <div className="register-success">
            <h2>Thank you for signing up for AFRY Partner!</h2>
            <h5>Important information!</h5>
            <p>You have taken the first step to become a partner to AFRY. <strong>You are currently an active employee</strong> and therefore you will not become a partner until after your active relation ends{status.currentEndDate !== null && ` (${moment(status.currentEndDate).format('YYYY-MM-DD')})` }. Once your current relation has ended you will receive an email with further information and a verification link to verify your email. <strong>It is mandatory to verify your email.</strong></p>
            <div className="register-success" style={{marginBottom: '1rem'}}>
              <a style={{textDecoration: 'underline', fontWeight: '700', fontSize: '16px'}} href="/">Go to login page</a>
            </div>
            <h5>Are you responsible for multiple partners?</h5>
            <p>If you are responsible for other partners, for example if you are in a sales position, every consultant that may be considered for assignments need to register their own profile. <u>Please note</u> that all consultants, due to GDPR, need to register their profile on their own. The link to your company and you as a contact person is made in the registration form. If you are the contact person to a profile, phone calls and emails concerning assignments will be directed to you</p>
          </div>
        </Column>
      </Row>
    );
  } else if (status.isActiveSubcon) {
    return (
      <Row>
        <Column {...RegisterColumns.fullColumn}>
          <div className="register-success">
            <h2>You are currently active in a non Partner assignment!</h2>
            <p>To keep quality assurance levels high, all Partner assignments need to be entered into as a Partner. Hence, your current assignment need to end before entering the Partner Network. According to our system information your assignment ends on {moment(status.currentEndDate).format('YYYY-MM-DD')}. <strong>Once your assignment has ended, you will be registered as a partner</strong> and then you will receive an email with further information and a verification link to verify your email. <strong>It is mandatory to verify your email.</strong></p>
            <div className="register-success" style={{marginBottom: '1rem'}}>
              <a style={{textDecoration: 'underline', fontWeight: '700', fontSize: '16px'}} href="/">Go to login page</a>
            </div>
            <h5>Are you responsible for multiple partners?</h5>
            <p>If you are responsible for other partners, for example if you are in a sales position, every consultant that may be considered for assignments need to register their own profile. <u>Please note</u> that all consultants, due to GDPR, need to register their profile on their own. The link to your company and you as a contact person is made in the registration form. If you are the contact person to a profile, phone calls and emails concerning assignments will be directed to you</p>
          </div>
        </Column>
      </Row>
    );

  } else if (status.alreadyExists) {
    return (
      <Row>
        <Column {...RegisterColumns.fullColumn}>
          <div className="register-success">
            <h2>You already have an active Partner account in our system!</h2>
            <p>
              <strong>If you try to create duplicate profiles you risk getting payment issues.</strong><br/>
              Please use this email address to login: {status.anonymizedEmail}.<br/>
              If you cannot access your account please contact the support at <a style={{textDecoration: 'underline'}} href="mailto:PartnerBusinessSupport@afry.com">PartnerBusinessSupport@afry.com</a></p>
            <div className="register-success" style={{marginBottom: '1rem'}}>
              <a style={{textDecoration: 'underline', fontWeight: '700', fontSize: '16px'}} href={portalUrl}>Go to login page</a>
            </div>
            <h5>Are you responsible for multiple partners?</h5>
            <p>If you are responsible for other partners, for example if you are in a sales position, every consultant that may be considered for assignments need to register their own profile. <u>Please note</u> that all consultants, due to GDPR, need to register their profile on their own. The link to your company and you as a contact person is made in the registration form. If you are the contact person to a profile, phone calls and emails concerning assignments will be directed to you</p>
          </div>
        </Column>
      </Row>
    );
  }
  return (
    <Row>
      <Column {...RegisterColumns.fullColumn}>
        <div className="register-success">
          <h2>Thank you for signing up for AFRY Partner!</h2>
          <p>You have taken the first step to become a partner to AFRY. Shortly, you will receive an email where you are asked to verify the email address that you registered. <strong>It is mandatory to verify your email.</strong> Once you have verified your email, you can log into the AFRY Portal where you can apply for available assignments and update your AFRY profile and CV. To get an overview of current assignment possibilities, please look at AFRY Available Assignments.</p>
          <h5>Increase your chances to get assignments at AFRY with a complete CV</h5>
          <p>To increase your chances to get the right assignment for your expertise, make sure that you have a complete CV. Update your profile continuously with new assignments, experiences, and courses. Edit your profile and CV in the <a href={portalUrl}>AFRY Portal</a> and go to the &ldquo;Profile & CV&rdquo; view</p>
          <h5>Are you responsible for multiple partners?</h5>
          <p>If you are responsible for other partners, for example if you are in a sales position, every consultant that may be considered for assignments need to register their own profile. <u>Please note</u> that all consultants, due to GDPR, need to register their profile on their own. The link to your company and you as a contact person is made in the registration form. If you are the contact person to a profile, phone calls and emails concerning assignments will be directed to you</p>
        </div>
      </Column>
    </Row>
  );
};

RegisterPartnerSuccess.propTypes = propTypes;
export default RegisterPartnerSuccess;
