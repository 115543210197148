import { combineReducers } from 'redux';

import countryReducer from './country';
import errorReducer from './error';
import loadingReducer from './loading';
import registerReducer from './register';
import successReducer from './success';
import cityReducer from './city';

const apiReducers = {
  country: countryReducer,
  error: errorReducer,
  loading: loadingReducer,
  register: registerReducer,
  success: successReducer,
  city: cityReducer
};

export default combineReducers({
  ...apiReducers,
});
