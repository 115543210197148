import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import DropdownContext from 'contexts/DropdownContext';
import './Dropdown.css';

const propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onToggle: PropTypes.func,
  open: PropTypes.bool,
};

const defaultProps = {
  className: undefined,
  disabled: false,
  onToggle: undefined,
  open: false,
};

function Dropdown(props) {
  const { className, disabled, children, onToggle, open } = props;
  const node = useRef(null);

  const handleToggle = (e) => {
    if (disabled) {
      e.preventDefault();
      return;
    }

    onToggle(e);
  };

  const handleDocumentClick = (e) => {
    if (node.current.contains(e.target) && node.current !== e.target) {
      return;
    }

    handleToggle(e);
  };

  const addEventListeners = () => {
    ['click'].forEach((event) =>
      document.addEventListener(event, handleDocumentClick, true)
    );
  };

  const removeEventListeners = () => {
    ['click'].forEach((event) =>
      document.removeEventListener(event, handleDocumentClick, true)
    );
  };

  useEffect(() => {
    if (open) {
      addEventListeners();
    }

    return () => {
      removeEventListeners();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const classes = classNames(className, 'apollo-dropdown');

  return (
    <DropdownContext.Provider value={{ onToggle, open }}>
      <div ref={node} className={classes} open={open}>
        {children}
      </div>
    </DropdownContext.Provider>
  );
}

Dropdown.propTypes = propTypes;
Dropdown.defaultProps = defaultProps;

export default Dropdown;
