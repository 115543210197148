import { actionTypes } from 'redux/actions/api/country';

const initialState = {
  countries: []
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: action.payload.countries
      };
    default:
      return state;
  }
};
