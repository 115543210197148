import React from 'react';
import Loader from 'components/loader';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Only a wrapper around 'components/loader.js' nowadays, together with the default
// styling ('margin: 0 auto') which was default
// for the old apollo loader based on react-lottie(not the same as react-lottie-player)
const sizePropType = PropTypes.oneOfType([PropTypes.number, PropTypes.string]);

const propTypes = {
  autoPlay: PropTypes.bool,
  height: sizePropType,
  loop: PropTypes.bool,
  width: sizePropType,
};

const defaultProps = {
  autoPlay: true,
  height: 250,
  loop: true,
  width: 250,
};

const StyledLoader = styled(Loader)`
  margin: 0 auto;
`;

const ApolloLoader = ({ autoPlay, height, loop, width }) => {
  return (
    <StyledLoader
      height={height}
      width={width}
      autoPlay={autoPlay}
      loop={loop}
    />
  );
};

ApolloLoader.propTypes = propTypes;
ApolloLoader.defaultProps = defaultProps;

export default ApolloLoader;
