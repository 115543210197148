import ReactGA from 'react-ga';

const initializeReactGA = (trackingCode, debug) => {
  if (!trackingCode) {
    ReactGA.initialize('dummy', { testMode: true });
    return;
  }

  ReactGA.initialize(trackingCode, {
    debug: debug,
    titleCase: true,
  });
};

export { initializeReactGA };
