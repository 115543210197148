import React from 'react';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie-player';
import dataJson from './data.json';

const sizePropType = PropTypes.oneOfType([PropTypes.number, PropTypes.string]);

const propTypes = {
  autoPlay: PropTypes.bool,
  height: sizePropType,
  loop: PropTypes.bool,
  width: sizePropType,
  className: PropTypes.string,
};

const defaultProps = {
  autoPlay: true,
  height: 250,
  loop: true,
  width: 250,
  className: undefined,
};

const Loader = ({ autoPlay, height, loop, width, className }) => {
  return <Lottie 
    animationData={dataJson}
    play={autoPlay}
    loop={loop}
    style={{ width, height }}
    className={className}
    rendererSettings={{
      preserveAspectRatio: 'xMidYMid slice',
    }}
  />;
};

Loader.propTypes = propTypes;
Loader.defaultProps = defaultProps;

export default Loader;