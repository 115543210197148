import { connect } from 'react-redux';
import { fetchCountries } from 'redux/services/api/country';
import CountrySelect from './country-select';

const mapStateToProps = (state) => ({
  countries: state.api.country.countries,
  // language: state.api.account.language,
  language: 'en',
});

const mapDispatchToProps = (dispatch) => ({
  fetchCountries: (languageCode) => dispatch(fetchCountries(languageCode)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CountrySelect);
