import { createStore, combineReducers, applyMiddleware } from 'redux';
import { createReduxHistoryContext } from 'redux-first-history';
import { createBrowserHistory } from 'history';
import { composeWithDevTools } from '@redux-devtools/extension';
import apiReducer from './redux/reducers/api';
import appsettingsReducer from './redux/reducers/appsettings';
import uiReducer from './redux/reducers/ui';
import thunk from 'redux-thunk';
import googleAnalyticsMiddleware from './redux/middlewares/google-analytics';

const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({
    history: createBrowserHistory(),
    //other options if needed
  });

const middlewares = [routerMiddleware, thunk, googleAnalyticsMiddleware];

export const store = createStore(
  combineReducers({
    router: routerReducer,
    api: apiReducer,
    appsettings: appsettingsReducer,
    ui: uiReducer,
  }),
  composeWithDevTools(applyMiddleware(...middlewares))
);

export const history = createReduxHistory(store);
