class RegisterColumns {
  static leftColumn = {
    mobile: {
      size: 12,
      offset: 0
    },
    tablet: {
      size: 5,
      offset: 1
    },
    desktop: {
      size: 5,
      offset: 1
    },
    largeDesktop: {
      size: 5,
      offset: 1
    }
  };

  static rightColumn = {
    mobile: {
      size: 12,
      offset: 0
    },
    tablet: {
      size: 5
    },
    desktop: {
      size: 5
    },
    largeDesktop: {
      size: 5
    }
  };

  static fullColumn = {
    mobile: {
      size: 12,
      offset: 0
    },
    tablet: {
      size: 10,
      offset: 1
    },
    desktop: {
      size: 10,
      offset: 1
    },
    largeDesktop: {
      size: 10,
      offset: 1
    }
  };

  static centeredColumns = {
    mobile: {
      size: 12,
      offset: 0
    },
    tablet: {
      size: 6,
      offset: 3
    },
    desktop: {
      size: 6,
      offset: 3
    },
    largeDesktop: {
      size: 6,
      offset: 3
    },
  };

  static centeredColumnsMedium = {
    mobile: {
      size: 12,
      offset: 0
    },
    tablet: {
      size: 8,
      offset: 2
    },
    desktop: {
      size: 8,
      offset: 2
    },
    largeDesktop: {
      size: 8,
      offset: 2
    }
  };

  static centeredColumnsLarge = {
    mobile: {
      size: 12,
      offset: 0
    },
    tablet: {
      size: 9,
      offset: 3
    },
    desktop: {
      size: 9,
      offset: 3
    },
    largeDesktop: {
      size: 9,
      offset: 3
    },
  };
}

export default RegisterColumns;
