import { actionTypes } from 'redux/actions/api/city';

const initialState = {
  cities: []
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CITIES_SUCCESS:
      return {
        ...state,
        cities: action.payload.cities
      };
    default:
      return state;
  }
};
