import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  FormFeedback,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from 'components/apollo';
import './validation-modal.css';
import classNames from 'classnames';

const propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  onAccept: PropTypes.func,
  onCancel: PropTypes.func,
  hideModal: PropTypes.func,
  acceptButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  isLoading: PropTypes.bool,
  disableAccept: PropTypes.bool,
  errorMessage: PropTypes.string,
  className: PropTypes.string,
};

const defaultProps = {
  title: undefined,
  message: undefined,
  onAccept: undefined,
  onCancel: undefined,
  hideModal: undefined,
  acceptButtonText: 'Accept',
  cancelButtonText: 'Cancel',
  isLoading: false,
  disableAccept: false,
  errorMessage: '',
  className: '',
};

const ValidationModal = (props) => {
  const {
    title,
    message,
    acceptButtonText,
    isLoading,
    cancelButtonText,
    errorMessage,
    onAccept,
    onCancel,
    disableAccept,
    hideModal,
    className,
  } = props;

  const [open, setOpen] = useState(true);

  const handleAccept = (e) => {
    if (onAccept) onAccept(e);
  };

  const handleCancel = (e) => {
    if (onCancel) onCancel(e);
    setOpen(false);
    };

  const modalClasses = classNames(
    className,
    "validation-modal"
  );

    return (
        <Modal
      className={modalClasses}
      contentClassName="validation-modal-content"
      dialogClassName="validation-modal-dialog"
      open={open}
      onClosed={() => hideModal()}
    >
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>{message}</ModalBody>
      <ModalFooter className="validation-modal-footer">
        <Button size="small" onClick={handleCancel} disabled={isLoading}>
          {cancelButtonText}
        </Button>
        <Button size="small" color="primary" onClick={handleAccept} loading={isLoading} disabled={disableAccept}>
          {acceptButtonText}
        </Button>
      </ModalFooter>
      { errorMessage && <FormFeedback className="error">{ errorMessage }</FormFeedback> }
    </Modal>
  );
};

ValidationModal.propTypes = propTypes;
ValidationModal.defaultProps = defaultProps;

export default ValidationModal;
