import React from 'react';
import PropTypes from 'prop-types';
import {
  Input,
  Label,
  Form,
  FormGroup,
  FormFeedback,
  FormText,
  Select,
  SelectOption
} from 'components/apollo';
import classNames from 'classnames';
import { Row, Column } from 'components/grid';
import moment from 'moment';
import RegisterColumns from '../../common/register-columns';
import Validation from '../../common/validation';
import './register-future-info-form.css';

const propTypes = {
  data: PropTypes.shape({
    graduationYear: PropTypes.number.isRequired
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onValidChange: PropTypes.func.isRequired,
  showErrors: PropTypes.bool.isRequired,
};

class RegisterFutureInfoForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = { school: '', specialization: '' };
  }

  componentDidMount() {
    const {
      data
    } = this.props;

    this.setState({
      ...data
    });
  }

  handleChange = (e) => {
    const {
      onChange,
      onValidChange
    } = this.props;
    const propertyName = e.target.name;
    const propertyValue = e.target.value;

    const newState = {
      ...this.state, [propertyName]: propertyValue
    };

    const {
      school,
      graduationYear,
      graduationMonth,
      specialization
    } = newState;

    const allValid = Validation.Future.school(school)
      && Validation.Future.graduationYear(graduationYear)
      && Validation.Future.graduationMonth(graduationMonth)
      && Validation.Future.specialization(specialization);

    onValidChange(allValid);

    this.setState({
      [propertyName]: e.target.value
    });

    onChange(propertyName, e.target.value);
  }

  handleGraduationChange = (date) => {
    const {
      onChange
    } = this.props;

    this.setState({
      graduation: date
    });

    onChange('graduation', date);
  }

  renderYearDropDown() {
    const {
      graduationYear
    } = this.state;

    const {
      showErrors
    } = this.props;

    const a = moment().year();
    const years = [];
    for (let i = a; i <= a + 20; i += 1) {
      years.push(i);
    }

    const yearOptions = years.map(x => <SelectOption key={x} value={x}>{x}</SelectOption>);

    return (
      <FormGroup>
        <Label for="graduationYear">Planned graduation year</Label>
        <Select name="graduationYear" onChange={this.handleChange} value={graduationYear}>
          {yearOptions}
        </Select>
        {showErrors && !Validation.Future.graduationYear(graduationYear) && (
          <FormFeedback>Grad year</FormFeedback>
        )}
      </FormGroup>
    );
  }

  renderMonthDropDown() {
    const {
      graduationMonth
    } = this.state;

    const {
      showErrors
    } = this.props;

    return (
      <FormGroup>
        <div className="labels">
          <Label for="graduationMonth">Planned graduation month</Label>
          <FormText>Optional</FormText>
        </div>
        <Select name="graduationMonth" onChange={this.handleChange} value={graduationMonth} placeholder="Month">
          <SelectOption value="0">Select a month</SelectOption>
          <SelectOption value="1">January</SelectOption>
          <SelectOption value="2">February</SelectOption>
          <SelectOption value="3">Mars</SelectOption>
          <SelectOption value="4">April</SelectOption>
          <SelectOption value="5">May</SelectOption>
          <SelectOption value="6">June</SelectOption>
          <SelectOption value="7">July</SelectOption>
          <SelectOption value="8">August</SelectOption>
          <SelectOption value="9">September</SelectOption>
          <SelectOption value="10">October</SelectOption>
          <SelectOption value="11">November</SelectOption>
          <SelectOption value="12">December</SelectOption>
        </Select>
        {showErrors && !Validation.Future.graduationMonth(graduationMonth) && (
          <FormFeedback>Grad month</FormFeedback>
        )}
      </FormGroup>
    );
  }


  render() {
    const {
      school,
      specialization
    } = this.state;

    const {
      showErrors
    } = this.props;

    const classes = classNames('register-future-info-form');

    return (
      <Form className={classes}>
        <Row>
          <Column {...RegisterColumns.fullColumn}>
            <h2>Future information</h2>
            <p>Please fill in your Future specific information in order to fulfill the registration.</p>
          </Column>
        </Row>
        <Row>
          <Column {...RegisterColumns.fullColumn}>
            <FormGroup>
              <Label for="school">Current school</Label>
              <Input name="school" onInput={this.handleChange} value={school} />
              {showErrors && !Validation.Future.school(school) && (
                <FormFeedback>Current school is required</FormFeedback>)}
            </FormGroup>
            {this.renderYearDropDown()}
            {this.renderMonthDropDown()}
            <FormGroup>
              <div className="labels">
                <Label for="specialization">Specialization</Label>
                <FormText>Optional</FormText>
              </div>
              <Input name="specialization" onInput={this.handleChange} value={specialization} />
              {showErrors && !Validation.Future.specialization(specialization) && (
                <FormFeedback>Specialization</FormFeedback>
              )}
            </FormGroup>
          </Column>
        </Row>
      </Form>
    );
  }
}

RegisterFutureInfoForm.propTypes = propTypes;
export default RegisterFutureInfoForm;
