import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Column } from 'components/grid';
import ReactGA from 'react-ga';
import RegisterColumns from '../../common/register-columns';
import PAGES from '../../utils/ga-constants';

const propTypes = {
  portalUrl: PropTypes.string.isRequired
};

const RegisterFutureSuccess = (props) => {
  useEffect(() => {
    ReactGA.ga('send', 'pageview', `${window.location.pathname}${PAGES.THANK_YOU}`);
  }, []);

  return (
    <Row>
      <Column {...RegisterColumns.fullColumn}>
        <div className="register-success">
          <h2>Thank you for signing up for AFRY Future Network!</h2>
          <p>You are now part of AFRY Future Network and will stay connected with us throughout your time as a student. We will keep you updated through newsletters, notifications about employment opportunities for students, and invitations to activities and events where you can meet us.</p>
          <p>You will shortly receive a confirmation email from us.</p>
        </div>
      </Column>
    </Row>
  );
};

RegisterFutureSuccess.propTypes = propTypes;
export default RegisterFutureSuccess;
