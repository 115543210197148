import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  justify-content: center;
`;

export const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1rem;

  p {
    margin-left: 12px;
    margin-bottom: 0px;
    font-size: 14px;
    color: gray;
  }
`;

export const Loader = styled.div`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  position: relative;

  .double-bounce1, .double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: steelblue;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    
    -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
    animation: sk-bounce 2.0s infinite ease-in-out;
  }

  .double-bounce2 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
  }

  @-webkit-keyframes sk-bounce {
    0%, 100% { -webkit-transform: scale(0.0) }
    50% { -webkit-transform: scale(1.0) }
  }
  
  @keyframes sk-bounce {
    0%, 100% { 
      transform: scale(0.0);
      -webkit-transform: scale(0.0);
    } 50% { 
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
  }
`;

Loader.defaultProps = {
    width: 40,
    height: 40
  };