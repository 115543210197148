import React from 'react';
import PropTypes from 'prop-types';
import {
  Input,
  Label,
  Form,
  FormGroup,
  FormFeedback,
  Checkbox
} from 'components/apollo';
import { Row, Column } from 'components/grid';
import GoogleRecaptcha from 'components/vendor/google/google-recaptcha';
import CommonValidation from 'utilities/validations/validation';
import RegisterColumns from '../../common/register-columns';
import Validation from '../../common/validation';
import { connect } from 'react-redux';

const propTypes = {
  companydomains: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
  onValidChange: PropTypes.func.isRequired,
  showErrors: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    email: PropTypes.string,
    password: PropTypes.string,
    passwordRepeat: PropTypes.string,
    privacyStatement: PropTypes.bool
  }),
  privacyStatementForNetworkers: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  registerPassword: PropTypes.bool,
};

const defaultProps = {
  data: {
    email: '',
    password: '',
    passwordRepeat: '',
    privacyStatement: false,
    captchaCompleted: false
  },
  companydomains: [],
  registerPassword: true,
};

class RegisterAccountInfoForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...defaultProps.data };
  }

  componentDidMount() {
    const {
      data,
      getAllCompanyDomains
    } = this.props;

    this.setState({
      ...data
    });

    getAllCompanyDomains();
  }

  handleChange = (e) => {
    const {
      name,
      value,
      checked,
      type
    } = e.target;

    const propertyValue = type === 'checkbox' ? checked : value;

    this.handleFormChange(name, propertyValue);
  }

  handleCaptchaSuccess = () => {
    this.handleFormChange('captchaCompleted', true);
  }

  handleFormChange = (name, value) => {
    const {
      onChange,
      onValidChange,
      companydomains,
      registerPassword,
      portalUrl
    } = this.props;

    if (name === 'email') {
      value = value.trim();
    }

    const newState = {
      ...this.state,
      [name]: value
    };

    let allValid = false;
    
    if(portalUrl === 'https://portalstage.afry.cloud/')
    {
      allValid = CommonValidation.Email.allowedEmailAndDomain(newState.email, companydomains)
      && Validation.Account.privacyStatement(newState.privacyStatement);
    }
    else {
      allValid = CommonValidation.Email.allowedEmailAndDomain(newState.email, companydomains)
      && Validation.Account.privacyStatement(newState.privacyStatement)
      && Validation.Account.captchaCompleted(newState.captchaCompleted);
    }

    if (registerPassword) {
      allValid = allValid && Validation.Account.password(newState.password)
      && Validation.Account.passwordRepeat(newState.password, newState.passwordRepeat);
    }
    
    onValidChange(allValid);

    this.setState({
      [name]: value
    });

    onChange(name, value);
  }

  render() {
    const {
      showErrors,
      privacyStatementForNetworkers,
      companydomains,
      registerPassword,
      portalUrl
    } = this.props;

    const {
      email,
      password,
      passwordRepeat,
      privacyStatement,
      captchaCompleted
    } = this.state;

    return (
      <Form>
        <Row>
          <Column {...RegisterColumns.fullColumn}>
            <h2>Account information</h2>
            <p>This is the final step to register your AFRY Networker Profile. Please fill in your account information.</p>
          </Column>
        </Row>
        <Row>
          <Column {...RegisterColumns.fullColumn}>
            <FormGroup>
              <Label for="email">Email</Label>
              <Input name="email" value={email} onChange={this.handleChange} />
              { showErrors && !CommonValidation.Email.allowedEmailAndDomain(email, companydomains) && (<FormFeedback>A valid email is required</FormFeedback>) }
            </FormGroup>
            { registerPassword && (
            <React.Fragment>
              <FormGroup>
                <Label for="password">Password</Label>
                <Input name="password" type="password" value={password} onChange={this.handleChange} />
                {showErrors && !Validation.Account.password(password) && (<FormFeedback>Password is required</FormFeedback>)}
              </FormGroup>
              <FormGroup>
                <Label for="passwordRepeat">Repeat password</Label>
                <Input name="passwordRepeat" type="password" value={passwordRepeat} onChange={this.handleChange} />
                {showErrors && !Validation.Account.passwordRepeat(password, passwordRepeat) && (
                  <FormFeedback>The passwords must match</FormFeedback>
                )}
              </FormGroup>
            </React.Fragment>
            )}
          </Column>
        </Row>
        <Row>
          <Column {...RegisterColumns.fullColumn}>
            <FormGroup>
              <Label for="privacyStatement">
                <Checkbox
                  id="privacyStatement"
                  name="privacyStatement"
                  checked={privacyStatement}
                  onChange={this.handleChange}
              /> I have read and understood AFRY's <a target="_blank" rel="noopener noreferrer" href={privacyStatementForNetworkers}>Privacy Policy</a>.
              </Label>
              {showErrors && !Validation.Account.privacyStatement(privacyStatement) && (
                <FormFeedback>You must accept the privacy agreement</FormFeedback>
              )}
            </FormGroup>            
            <FormGroup> 
            {portalUrl !== 'https://portalstage.afry.cloud/' && (           
              <GoogleRecaptcha onCaptchaVerified={this.handleCaptchaSuccess} /> )}
              {showErrors && !Validation.Account.captchaCompleted(captchaCompleted) && (
                <FormFeedback>reCaptcha not valid</FormFeedback>
              )}                            
            </FormGroup>            
          </Column>
        </Row>
      </Form>
    );
  }
}

RegisterAccountInfoForm.defaultProps = defaultProps;
RegisterAccountInfoForm.propTypes = propTypes;

const mapStateToProps = state => ({portalUrl: state.appsettings.andromeda.portalUrl});

export default connect(mapStateToProps, null)(RegisterAccountInfoForm);
