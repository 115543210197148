import { connect } from 'react-redux';
import { fetchCities } from 'redux/services/api/city';
import CitySelect from './city-select';

const mapStateToProps = (state) => ({
  cities: state.api.city.cities
});

const mapDispatchToProps = (dispatch) => ({
  fetchCities: (countryKey) => dispatch(fetchCities(countryKey)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CitySelect);
