export const actionTypes = {
  GET_COUNTRIES_REQUEST: 'GET_COUNTRIES_REQUEST',
  GET_COUNTRIES_SUCCESS: 'GET_COUNTRIES_SUCCESS',
  GET_COUNTRIES_FAILURE: 'GET_COUNTRIES_FAILURE',
};

export const getCountriesRequest = () => ({
  type: actionTypes.GET_COUNTRIES_REQUEST,
});

export const getCountriesSuccess = data => ({
  type: actionTypes.GET_COUNTRIES_SUCCESS,
  payload: data
});

export const getCountriesFailure = error => ({
  type: actionTypes.GET_COUNTRIES_FAILURE,
  payload: error
});
