import { connect } from 'react-redux';
import { getAllCompanyDomains } from 'redux/services/api/register';
import RegisterAccountInfoForm from './register-account-info-form';

const mapStateToProps = state => ({
  privacyStatementForNetworkers: state.appsettings.privacyPolicy.privacyStatementForNetworkers,
  companydomains: state.api.register.companydomains,
  companyName: state.appsettings.company.name,
});

const mapDispatchToProps = dispatch => ({
  getAllCompanyDomains: () => {
    dispatch(getAllCompanyDomains());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterAccountInfoForm);
