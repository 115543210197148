import moment from 'moment';
import { isValidPhoneNumber } from 'react-phone-number-input/max';

class Validation {
  static Person = {
    firstName: field => !!field && field !== '',
    lastName: field => !!field && field !== '',
    birthDay: field => !!field && field !== '' && moment(field, 'YYYY-MM-DD', true).isValid(),
    phone: field => isValidPhoneNumber(field),
    geoLocationKey: field => !!field && field !== '',
    streetAddress: field => !!field && field !== '',
    zipCode: field => !!field && field !== '' && field.length >= 2,
    country: field => !!field && field !== '',
    linkedin: field => field === null
      || field === undefined
      || field === ''
      || field.toLowerCase().indexOf('linkedin.com/') >= 0,
    civicRegNumberLast: (field, selectedLocale) => field === undefined
      || field === ''
      || Validation.civicLastDigits[selectedLocale].pattern.test(field),
    civicRegNumberCheckSum: (datePart, lastPart, selectedLocale) => lastPart === undefined
      || lastPart === ''
      || Validation.civicLastDigits[selectedLocale].isValidChecksum(datePart, lastPart),
  }

  static Partner = {
    organizationNumber: field => !!field && field !== '',
    companyName: field => !!field && field !== '',
    webpage: () => true,
    salesManager: (responsible, manager) => (responsible || (!!manager && manager !== '')),
    phoneMobileSalesManager: (responsible, valid) => responsible || valid,
    emailSalesManager: (responsible, email) => responsible || (!!email && email !== '' && email.indexOf('@') > 0)
  }

  static Future = {
    school: field => !!field && field !== '',
    graduationYear: field => !!field && field !== '',
    graduationMonth: () => true,
    specialization: () => true,
  }

  static Account = {
    email: field => !!field && field !== '' && field.indexOf('@') > 0,
    allowedEmailAndDomain: (field, afDomains) => {
      const containsAfDomain = afDomains.some(domain => field.trim().endsWith(domain));
      return Validation.Account.email(field) && !containsAfDomain;
    },
    password: field => !!field && field !== '' && field.length >= 1,
    passwordRepeat: (p1, p2) => p1 === p2,
    privacyStatement: field => field,
    captchaCompleted: field => field
  }

  static civicLastDigits = {
    SE: { // swedish
      locale: 'SE',
      digitlength: 4,
      placeholder: 'NNNN',
      format: 'YYMMDD',
      dateFormat: (input) => {
        const d = moment.utc(input);
        return d.clone().format('YYMMDD');
      },
      pattern: /[0-9]{4}/,
      isValidChecksum: (datePart, lastDigits) => {
        const fullNumber = `${datePart}${lastDigits}`;
        let multiplier = 2;
        const numbers = [];
        for (let i = 0; i < fullNumber.length - 1; i += 1) {
          numbers[i] = fullNumber[i] * multiplier;
          multiplier = multiplier === 1 ? 2 : 1;
        }

        const sum = numbers.reduce((x, y) => {
          if (y >= 10) {
            return x + Math.floor(y / 10) + (y % 10);
          }
          return x + y;
        }, 0);

        const checkSum = (10 - (sum % 10)) % 10;

        return lastDigits[lastDigits.length - 1] === checkSum.toString();
      }
    },
    NO: { // nynorsk
      locale: 'NO',
      digitlength: 5,
      placeholder: 'NNNNN',
      format: 'DDMMYY',
      dateFormat: (input) => {
        const d = moment.utc(input);
        return d.clone().format('DDMMYY');
      },
      pattern: /[0-9]{5}/,
      isValidChecksum: () => true
    },
    DK: { // danish
      locale: 'DK',
      digitlength: 4,
      placeholder: 'NNNN',
      format: 'DDMMYY',
      dateFormat: (input) => {
        const d = moment.utc(input);
        return d.clone().format('DDMMYY');
      },
      pattern: /[0-9]{4}/,
      isValidChecksum: () => true
    },
    FI: { // finnish
      locale: 'FI',
      digitlength: 5,
      placeholder: 'SNNNK',
      format: 'DDMMYY',
      dateFormat: (input) => {
        const d = moment.utc(input);
        return d.clone().format('DDMMYY');
      },
      pattern: /[-A][0-9]{3}[0-9A-FHJ-NPR-Y]/,
      isValidChecksum: () => true
    },
    otherCountry: {
      dateFormat: (input) => {
        const d = moment.utc(input);
        return d.clone().format('YYMMDD');
      },
      isValidChecksum: () => true
    }
  }
}

export default Validation;
