import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import DropdownContext from 'contexts/DropdownContext';
import './DropdownMenu.css';

const propTypes = {
  className: PropTypes.string,
  right: PropTypes.bool,
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
};

const defaultProps = {
  className: undefined,
  right: false,
  tag: 'div',
};

const DropdownMenu = (props) => {
  const { className, right, tag: Tag, ...other } = props;
  const { open } = useContext(DropdownContext);

  const classes = classNames(
    className,
    'apollo-dropdown-menu',
    open ? 'apollo-open' : '',
    right ? 'apollo-dropdown-menu-right' : ''
  );

  return <Tag {...other} className={classes} />;
};

DropdownMenu.propTypes = propTypes;
DropdownMenu.defaultProps = defaultProps;

export default DropdownMenu;
