// Note: This file is mostly for legacy purposes since our configuration was previously injected into redux during runtime.
// Since the configuration is now statically compiled they do not need to be injected this way. You can insted use process.env.REACT_APP_MY_CONFIG anywhere in your code.

export const reduxAppsettings = {
  recaptcha: {
    siteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
  },
  privacyPolicy: {
    privacyStatementForNetworkers:
      process.env.REACT_APP_PRIVACY_POLICY_PRIVACY_STATEMENT_FOR_NETWORKERS,
  },
  andromeda: {
    portalUrl: process.env.REACT_APP_ANDROMEDA_PORTAL_URL,
  },
  googleAnalytics: {
    trackingCode: process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_CODE,
    debug: process.env.REACT_APP_GOOGLE_ANALYTICS_DEBUG === 'true',
  },
  company: {
    name: process.env.REACT_APP_COMPANY_NAME,
  },
};
