import { connect } from 'react-redux';
import RegisterFutureSuccess from './register-future-success';

const mapStateToProps = state => ({
  portalUrl: state.appsettings.andromeda.portalUrl,
  companyName: state.appsettings.company.name,
});

export default connect(
  mapStateToProps,
  null
)(RegisterFutureSuccess);
