import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Navbar.css';

const propTypes = {
  className: PropTypes.string,
  dark: PropTypes.bool,
  expand: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  fixed: PropTypes.string,
  role: PropTypes.string,
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
};

const defaultProps = {
  className: undefined,
  dark: false,
  expand: false,
  fixed: undefined,
  role: undefined,
  tag: 'nav',
};

const Navbar = ({ className, dark, expand, fixed, tag: Tag, ...other }) => {
  const classes = classNames(
    className,
    'apollo-navbar',
    dark ? 'apollo-navbar-dark' : 'apollo-navbar-light',
    (typeof expand === 'boolean' && expand) || expand === 'x-small'
      ? 'apollo-navbar-expand'
      : `apollo-navbar-expand-${expand}`,
    fixed ? `apollo-navbar-fixed-${fixed}` : '',
  );

  return <Tag {...other} className={classes} />;
};

Navbar.propTypes = propTypes;
Navbar.defaultProps = defaultProps;

export default Navbar;
