import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Collapse.css';

const propTypes = {
  className: PropTypes.string,
  navbar: PropTypes.bool,
  open: PropTypes.bool,
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
};

const defaultProps = {
  className: undefined,
  navbar: false,
  open: false,
  tag: 'div',
};

const Collapse = ({ className, navbar, open, tag: Tag, ...other }) => {
  const classes = classNames(
    className,
    'apollo-collapse',
    navbar ? 'apollo-navbar-collapse' : '',
    open ? 'apollo-open' : '',
  );

  return <Tag {...other} className={classes} />;
};

Collapse.propTypes = propTypes;
Collapse.defaultProps = defaultProps;

export default Collapse;
