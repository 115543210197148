import React from 'react';
import PropTypes from 'prop-types';
import {
  FormGroup,
  FormFeedback,
  Label,
  Input,
} from 'components/apollo';
import {
  Field,
  ErrorMessage,
} from 'formik';

const propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.oneOf([
    'text',
    'number',
    'password',
    'email',
    'tel'
  ])
};

const defaultProps = {
  placeholder: '',
  disabled: false,
  type: 'text'
};

const FieldSet = ({
  name,
  label,
  placeholder,
  disabled,
  type
}) => (
  <FormGroup>
    <Label for={name}>{label}</Label>
    <Field
      id={name}
      name={name}
    >
      {({
        field, // { name, value, onChange, onBlur }
        form,
      }) => (
        <Input
          type={type}
          {...field}
          id={field.name}
          placeholder={placeholder}
          disabled={disabled || form.isSubmitting}
        />
      )}
    </Field>
    <FormFeedback>
      <ErrorMessage name={name} />
    </FormFeedback>
  </FormGroup>
);

FieldSet.propTypes = propTypes;
FieldSet.defaultProps = defaultProps;

export default FieldSet;
