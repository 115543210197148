import { connect } from 'react-redux';
import { hideModal, showModal } from 'redux/actions/ui/modal';
import { withRouter } from 'react-router';
import CreateGuestAccount from './create-guest-account';

const mapDispatchToProps = dispatch => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (type, props) => dispatch(showModal(type, props))
});

export default withRouter(connect(
  null,
  mapDispatchToProps,
)(CreateGuestAccount));
