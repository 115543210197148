import React, { Suspense } from 'react';
import { Switch, Route } from 'react-router';
import { appRoutes } from './app-routes';
import RegisterRouter from './areas/register';
import GuestInvitationRouter from './areas/guest-invitation';
import VerificationRouter from './areas/verification';
import ConfirmActiveRouter from './areas/confirm-active';

import { NotFoundContainer } from './views/shared/not-found';

const AppRouter = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        <Route path={appRoutes.register} component={RegisterRouter} />
        <Route path={appRoutes.verification} component={VerificationRouter} />
        <Route path={appRoutes.confirmActive} component={ConfirmActiveRouter} />
        <Route path={appRoutes.guestInvitation} component={GuestInvitationRouter} />

        <Route path="*" component={NotFoundContainer} />
      </Switch>
    </Suspense>
  );
};

export default AppRouter;
