import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Loader,
} from 'components/apollo';
import classNames from 'classnames';
import './register-footer.css';

const propTypes = {
  showNext: PropTypes.bool.isRequired,
  showBack: PropTypes.bool.isRequired,
  onBack: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  saving: PropTypes.bool,
};

const defaultProps = {
  saving: false
};

const RegisterFooter = (props) => {
  const {
    showNext,
    showBack,
    onBack,
    onNext,
    onSubmit,
    saving,
    partnerInfo,
  } = props;

  const footerClasses = classNames('register-footer');
  const controlClasses = classNames('register-control');
  const placeholderClass = classNames('button-placeholder');

  if (saving) {
    return (
      <div className={footerClasses}>
        <Loader height={100} width={300} />
      </div>
    );
  }

  return (
    <div>
      <div className="register-footer-mobile-container">
        <div className="register-footer-mobile">
          {
            showBack
              ? <Button onClick={onBack}>Back</Button>
              : <div className={placeholderClass} />
          }
          {
            showNext
              ? <Button color="primary" onClick={onNext}>Next</Button>
              : <Button color="success" onClick={onSubmit}>Register</Button>
          }
        </div>
      </div>
      <div className={footerClasses}>
        <div className={controlClasses}>
          {showBack && (<Button onClick={onBack}>Back</Button>)}
          {!showBack && (<div className={placeholderClass} />)}
          <p>
            Contact Partner Business Support if you need assistance! <br/>
            Phone: + 46 10 5059015 (Tuesdays and Thursdays, 09.00-15.00 CET) <br/>
            Email:  {partnerInfo 
                      ? <a href="mailto:PartnerBusinessSupport@afry.com">PartnerBusinessSupport@afry.com</a>
                      : <a href="mailto:support@afry.com">support@afry.com</a>
                    }
          </p>
          {showNext && (<Button color="primary" onClick={onNext}>Next</Button>)}
          {!showNext && (<Button color="success" onClick={onSubmit}>Register</Button>)}
        </div>
      </div>
    </div>);
};

RegisterFooter.defaultProps = defaultProps;
RegisterFooter.propTypes = propTypes;
export default RegisterFooter;
