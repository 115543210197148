import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Navbar, Loader, Button } from 'components/apollo';
import { Row, Column } from 'components/grid';
import './verification-email.css';
import AFNavbarBrand from 'components/common/af-navbar-brand';
import axios from 'axios';

const headers = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

const verifyEmail = async (token) => {
  // Log
  try {
    await axios.post(
      `${process.env.REACT_APP_ANDROMEDA_ACCOUNTMANAGEMENT_BASEURL}/Log/VerifyEmail`,
      JSON.stringify(token),
      headers
    );
  } catch {
    // Catch and do nothing if logging fails.
  }

  await axios.post(
    `${process.env.REACT_APP_ANDROMEDA_REGISTER_BASEURL}/Verification/Email`,
    JSON.stringify(token),
    headers
  );
};

const contentCols = {
  mobile: {
    size: 8,
    offset: 2,
  },
  tablet: {
    size: 8,
    offset: 2,
  },
  desktop: {
    size: 8,
    offset: 2,
  },
  largeDesktop: {
    size: 8,
    offset: 2,
  },
};

function VerificationEmail() {
  const { token } = useParams();

  const [error, setError] = useState(false);
  const [done, setDone] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        await verifyEmail(token);
        setDone(true);
        setError(false);
      } catch (e) {
        setDone(true);
        setError(true);
      }
    })();
  }, [token]);

  if (!done) {
    return <Loader />;
  }

  const header = error ? 'Email not verified' : 'Email verified';
  const content = error
    ? 'Your email was not verified correctly'
    : 'Your email was verified correctly, you can now proceed to login';

  return (
    <div className="verification">
      <Navbar dark fixed="top" expand="large">
        <Container>
          <AFNavbarBrand />
        </Container>
      </Navbar>
      <Container>
        <Row>
          <Column {...contentCols}>
            <div className="verification-content">
              <h2>{header}</h2>
              <p>{content}</p>
              <a href={`${process.env.REACT_APP_ANDROMEDA_PORTAL_URL}login`}>
                <Button color="primary">To login</Button>
              </a>
            </div>
          </Column>
        </Row>
      </Container>
    </div>
  );
}

export default VerificationEmail;
