import React from 'react';
import { connect } from 'react-redux';

import AlertModalContainer from './alert-modal';
import ValidationModalContainer from './validation-modal';

const modalComponents = {
  ALERT: AlertModalContainer,
  VALIDATION: ValidationModalContainer
};

const ModalContainer = (props) => {
  const { modal } = props;
  if (!modal.type) {
    return null;
  }

  const ModalComponent = modalComponents[modal.type];
  return <ModalComponent {...modal.props} />;
};

const mapStateToProps = state => ({
  modal: state.ui.modal,
});

export default connect(
  mapStateToProps,
  null
)(ModalContainer);
