import { actionTypes } from 'redux/actions/ui/modal';

const initialState = {
  type: null,
  props: {}
};

const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SHOW_MODAL:
      return {
        ...state,
        type: action.payload.type,
        props: action.payload.props
      };
    case actionTypes.HIDE_MODAL:
      return initialState;
    default:
      return state;
  }
};

export default modalReducer;
