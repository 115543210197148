import axios from 'axios';
import * as actions from 'redux/actions/api/city';

const BASE_URL = process.env.REACT_APP_ANDROMEDA_REGISTER_BASEURL;

export const fetchCities = countryKey => (dispatch) => {
  dispatch(actions.getCitiesRequest());

  const config = {
    headers: {
      Accept: 'application/json',
      Pragma: 'no-cache',
      'Cache-Control': 'no-cache'
    }
  };

  axios.get(`${BASE_URL}/location/cities/${countryKey}`, config)

    .then((response) => {
      const cities = { cities: response.data };
      dispatch(actions.getCitiesSuccess(cities));
    })
    .catch((error) => {
      dispatch(actions.getCitiesFailure(error));
    });
};
