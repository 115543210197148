import { connect } from 'react-redux';
import { fetchCountries } from 'redux/services/api/country';
import { fetchCities } from 'redux/services/api/city';
import { registerPartner } from 'redux/services/api/register';
import RegisterPartner from './register-partner';

const mapStateToProps = (state) => ({
  success: state.api.register.success,
  countries: state.api.country.countries,
  cities: state.api.city.cities,
  azureAd: true,
});

const mapDispatchToProps = (dispatch) => ({
  register: (formData) => dispatch(registerPartner(formData)),
  fetchCountries: (languageCode) => dispatch(fetchCountries(languageCode)),
  fetchCities: (countryKey) => dispatch(fetchCities(countryKey)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPartner);
