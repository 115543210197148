import React from 'react';
import PropTypes from 'prop-types';
import {
  Input,
  Label,
  Form,
  FormGroup,
  FormFeedback,
  FormText,
  Checkbox
} from 'components/apollo';
import { Row, Column } from 'components/grid';
import PhoneInput, { PhoneValidMsg } from 'components/inputs/phone-input2';
import SettingsTooltip from '../../../settings/components/settings-tooltip';
import RegisterColumns from '../../common/register-columns';
import Validation from '../../common/validation';
import './register-partner-info-form.css';

const propTypes = {
  onChange: PropTypes.func.isRequired,
  onValidChange: PropTypes.func.isRequired,
  showErrors: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    companyNotStarted: PropTypes.bool,
    organizationNumber: PropTypes.string,
    companyName: PropTypes.string,
    webpage: PropTypes.string,
    communicationResponsible: PropTypes.bool,
    salesManager: PropTypes.string,
    phoneMobileSalesManager: PropTypes.string,
    emailSalesManager: PropTypes.string,
    defaultOrgNumber: PropTypes.string.isRequired,
  })
};

const defaultProps = {
  data: {
    companyNotStarted: false,
    organizationNumber: '',
    companyName: '',
    webpage: '',
    communicationResponsible: true,
    salesManager: '',
    phoneMobileSalesManager: '',
    emailSalesManager: ''
  }
};

const tooltipText = [
  'The communication from AFRY, via email and phone, is directed to the sales responsible. Newsletters will be sent directly to the registered AFRY Network Profile.'
];

const rebelMailContent = `mailto:info@rebelwork.se?subject=AFRY Partner - get an offer from Rebel Work
&body=Hi,${escape('\n')}I would like help to start my company and/or with the accounting and admin by getting an offer from Rebel Work.${escape('\n')}
Please contact me further.${escape('\n\n')}
Name: <Fill in your details>${escape('\n')}
Email: <Fill in your details>${escape('\n')}
Phone: <Fill in your details>${escape('\n\n')}
Thanks in advance!`;
                
class RegisterPartnerInfoForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      phoneMobileSalesValid: null
    }
  }

  componentDidUpdate() {
    const { onValidChange, data } = this.props;
    const { phoneMobileSalesValid } = this.state;
    const {
      organizationNumber, companyName, webpage, communicationResponsible, salesManager, emailSalesManager, valid
    } = data;
    const allValid = Validation.Partner.organizationNumber(organizationNumber)
      && Validation.Partner.companyName(companyName)
      && Validation.Partner.webpage(webpage)
      && Validation.Partner.salesManager(communicationResponsible, salesManager)
      && Validation.Partner.phoneMobileSalesManager(communicationResponsible, phoneMobileSalesValid === PhoneValidMsg.valid)
      && Validation.Partner.emailSalesManager(communicationResponsible, emailSalesManager);
    if (valid !== allValid) {
      onValidChange(allValid);
    }
  }

  handleChange = (e) => {
    const {
      name,
      value,
      checked,
      type
    } = e.target;
    this.handleFormChange(name, type === 'checkbox' ? checked : value);
  }

  handlePhoneChange = (number) => {
    this.handleFormChange('phoneMobileSalesManager', number);
  }

  handlePhoneValid = (validMsg) => {
    const { phoneMobileSalesValid } = this.state;
    if (phoneMobileSalesValid !== validMsg) {
      this.setState({ phoneMobileSalesValid: validMsg });
    }
  }

  getPhoneSalesErrorMsg = (valid) => {
    switch (valid) {
      case PhoneValidMsg.required:
        return 'Phone sales responsible is required';
      case PhoneValidMsg.removeZero:
        return 'Remove the first zero from number';
      default:
        return 'Phone sales number is invalid';
    }
  }

  handleFormChange = (name, value) => {
    const {
      onChange,
    } = this.props;
    onChange(name, value);
  }

  render() {
    const { showErrors, data } = this.props;
    const {
      companyNotStarted,
      organizationNumber,
      companyName,
      webpage,
      communicationResponsible,
      salesManager,
      phoneMobileSalesManager,
      emailSalesManager,
    } = data;
    const { phoneMobileSalesValid } = this.state;

    return (
      <Form>
        <Row>
          <Column {...RegisterColumns.fullColumn}>
            <h2>Partner information</h2>
            <p>Please fill in your partner specific information in order to fulfill the registration.</p>
          </Column>
        </Row>
        <Row>
          <Column {...RegisterColumns.fullColumn}>
            <h5>Company details</h5>
          </Column>
        </Row>
        <Row>
          <Column {...RegisterColumns.fullColumn}>
            <FormGroup>
              <Label for="companyNotStarted">
                <Checkbox
                  id="companyNotStarted"
                  name="companyNotStarted"
                  onChange={this.handleChange}
                  checked={companyNotStarted}
                />
                I have not started my company yet
              </Label>
              <FormText>You can register as a partner even though you have not started your company yet.</FormText>
            </FormGroup>
            <FormGroup>
                <FormText>
                    &bull; Would you like help and support to start your company and/or with the accounting and administration? 
                    Get an offer from <a target="_blank" rel="noreferrer" href="https://rebelwork.se/afry">Rebel Work</a> by sending an email to <a href={rebelMailContent}>info@rebelwork.se</a>.
                </FormText>
            </FormGroup>
          </Column>
        </Row>
        <Row>
          <Column {...RegisterColumns.leftColumn}>
            <FormGroup>
              <Label for="companyName">Company name</Label>
              <Input name="companyName" onChange={this.handleChange} value={companyName} />
              {showErrors && !Validation.Partner.companyName(companyName) && (
                <FormFeedback>Company name is required</FormFeedback>
              )}
            </FormGroup>
          </Column>
          <Column {...RegisterColumns.rightColumn}>
            <FormGroup>
              <Label for="organizationNumber">Organization number</Label>
              <Input name="organizationNumber" onChange={this.handleChange} value={organizationNumber} maxLength="20" />
              {showErrors && !Validation.Partner.organizationNumber(organizationNumber) && (
                <FormFeedback>Organization number is required</FormFeedback>
              )}
            </FormGroup>
          </Column>
        </Row>
        <Row>
          <Column {...RegisterColumns.leftColumn}>
            <FormGroup>
              <div className="labels">
                <Label for="webpage">Webpage</Label>
                <FormText>Optional</FormText>
              </div>
              <Input name="webpage" onChange={this.handleChange} value={webpage} />
            </FormGroup>
          </Column>
        </Row>
        <Row>
          <Column
            mobile={{ size: 12, offset: 0 }}
            tablet={{ size: 5, offset: 1 }}
            desktop={{ size: 5, offset: 1 }}
            largeDesktop={{ size: 5, offset: 1 }}
          >
            <SettingsTooltip content={tooltipText}>
              <h5 className="section-header">Communications & sales</h5>
            </SettingsTooltip>
          </Column>
        </Row>
        <Row>
          <Column {...RegisterColumns.leftColumn}>
            <FormGroup>
              <Label for="communicationResponsible">
                <Checkbox
                  id="communicationResponsible"
                  name="communicationResponsible"
                  onChange={this.handleChange}
                  checked={communicationResponsible}
                />
                I am responsible for communication with AFRY
              </Label>
            </FormGroup>
          </Column>
        </Row>
        <Row>
          <Column {...RegisterColumns.leftColumn}>
            <FormGroup>
              <Label for="salesManager">Sales responsible</Label>
              <Input
                name="salesManager"
                disabled={communicationResponsible}
                onChange={this.handleChange}
                value={salesManager}
              />
              {showErrors && !Validation.Partner.salesManager(communicationResponsible, salesManager) && (
                <FormFeedback>Sales responsible is required</FormFeedback>
              )}
              {!communicationResponsible && (<FormText>Enter first and last name</FormText>)}
            </FormGroup>
          </Column>
          <Column {...RegisterColumns.rightColumn}>
            <FormGroup>
              <Label for="phoneMobileSalesManager">Phone sales responsible</Label>
              <PhoneInput
                onValidChange={this.handlePhoneValid}
                value={phoneMobileSalesManager}
                onChange={this.handlePhoneChange}
                disabled={communicationResponsible}
              />
              {showErrors && !Validation.Partner.phoneMobileSalesManager(communicationResponsible, phoneMobileSalesValid === PhoneValidMsg.valid) && (
              <FormFeedback>{this.getPhoneSalesErrorMsg(phoneMobileSalesValid)}</FormFeedback>
              )}
            </FormGroup>
          </Column>
        </Row>
        <Row>
          <Column {...RegisterColumns.leftColumn}>
            <FormGroup>
              <Label for="emailSalesManager">Email sales responsible</Label>
              <Input
                name="emailSalesManager"
                onChange={this.handleChange}
                value={emailSalesManager}
                disabled={communicationResponsible}
              />
              {showErrors && !Validation.Partner.emailSalesManager(communicationResponsible, emailSalesManager) && (
                <FormFeedback>A valid email for sales responsible is required</FormFeedback>
              )}
            </FormGroup>
          </Column>
        </Row>
      </Form>
    );
  }
}

RegisterPartnerInfoForm.defaultProps = defaultProps;
RegisterPartnerInfoForm.propTypes = propTypes;
export default RegisterPartnerInfoForm;
