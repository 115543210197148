import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import DropdownContext from 'contexts/DropdownContext';
import './DropdownItem.css';

const propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  header: PropTypes.bool,
  href: PropTypes.string,
  onClick: PropTypes.func,
  separator: PropTypes.bool,
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
};

const defaultProps = {
  active: false,
  className: undefined,
  disabled: false,
  header: false,
  href: undefined,
  onClick: undefined,
  separator: false,
  tag: 'button',
};

function DropdownItem(props) {
  const {
    active,
    className,
    disabled,
    header,
    href,
    onClick,
    separator,
    ...other
  } = props;

  let { tag: Tag } = props;

  const { onToggle } = useContext(DropdownContext);

  const handleClick = (e) => {
    if (disabled) {
      e.preventDefault();
      return;
    }

    if (onClick) {
      onClick(e);
    }

    if (onToggle) {
      onToggle(e);
    }
  };

  const classes = classNames(
    className,
    !header && !separator ? 'apollo-dropdown-item' : '',
    header ? 'apollo-dropdown-header' : '',
    separator ? 'apollo-dropdown-separator' : '',
    active ? 'apollo-active' : ''
  );

  if (Tag === 'button') {
    if (header) {
      Tag = 'h6';
    } else if (separator) {
      Tag = 'div';
    } else if (href) {
      Tag = 'a';
    }
  }

  return (
    <Tag {...other} className={classes} href={href} onClick={handleClick} />
  );
}

DropdownItem.propTypes = propTypes;
DropdownItem.defaultProps = defaultProps;

export default DropdownItem;
