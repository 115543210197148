import axios from 'axios';
import * as actions from 'redux/actions/api/register';

const BASE_URL = process.env.REACT_APP_ANDROMEDA_REGISTER_BASEURL;

// eslint-disable-next-line import/prefer-default-export
export const registerPartner = (formData) => (dispatch) => {
  dispatch(actions.registerPartnerRequest());

  const data = { ...formData };

  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };

  axios
    .post(`${BASE_URL}/register/partner`, data, headers)
    .then((response) => {
      dispatch(actions.registerPartnerSuccess(response.data));
    })
    .catch((error) => {
      dispatch(actions.registerPartnerFailure(error));
    });
};

export const registerFuture = (formData) => (dispatch) => {
  dispatch(actions.registerFutureRequest());

  const data = { ...formData };

  const headers = {
    'Content-Type': 'application/json',
  };

  axios
    .post(`${BASE_URL}/register/future`, data, headers)
    .then((response) => {
      dispatch(actions.registerFutureSuccess(response.data));
    })
    .catch((error) => {
      dispatch(actions.registerFutureFailure(error));
    });
};

export const getAllCompanyDomains = () => (dispatch) => {
  dispatch(actions.getAllCompanyDomainsRequest());
  axios
    .get(`${BASE_URL}/register/company-domains`)
    .then((response) => {
      dispatch(actions.getAllCompanyDomainsSuccess(response.data));
    })
    .catch((error) => {
      dispatch(actions.getAllCompanyDomainsFailure(error));
    });
};
