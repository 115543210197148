import { combineReducers } from 'redux';
import modalReducer from './modal';

const uiReducers = {
  modal: modalReducer,
};

export default combineReducers({
  ...uiReducers,
});
