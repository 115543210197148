import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import DropdownContext from 'contexts/DropdownContext';
import './DropdownToggle.css';

const propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  id: PropTypes.string,
};

const defaultProps = {
  active: false,
  className: undefined,
  disabled: false,
  onClick: undefined,
};

function DropdownToggle({ active, className, disabled, onClick, children, id }) {
  const { onToggle, open } = useContext(DropdownContext);

  const handleClick = (e) => {
    if (disabled) {
      e.preventDefault();
      return;
    }

    if (onClick) {
      onClick(e);
    }

    onToggle(e);
  }

    const classes = classNames(
      className,
      'apollo-dropdown-toggle',
      active || open ? 'apollo-active' : '',
      disabled ? 'apollo-disabled' : '',
    );

    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a
        className={classes}
        disabled={disabled}
        onClick={handleClick}
        id={id}
      >
        {children}
      </a>
    );


}

DropdownToggle.propTypes = propTypes;
DropdownToggle.defaultProps = defaultProps;

export default DropdownToggle;
