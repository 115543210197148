import React from 'react';
import { Route, Switch } from 'react-router';
import { appRoutes } from '../../../app-routes';
import { NotFoundContainer } from '../../../views/shared/not-found';
import InvitationTermsOfUse from '../components/terms-of-use/terms-of-use';
import InvitationToCreateGuestAccount from '../views/invitation-to-create-guest-account/invitation-to-create-guest-account';

const parentRoutePath = appRoutes.guestInvitation;

export const guestInvitationRoutes = {
  root: parentRoutePath,
  invitation: `${parentRoutePath}/guest/:invitationId`,
  invitationTerms: `${parentRoutePath}/terms-of-use`,
};

const GuestInvitationRouter = () => (

  <Switch>
    <Route
      exact
      path={guestInvitationRoutes.invitation}
      component={InvitationToCreateGuestAccount}
    />
    <Route
      exact
      path={guestInvitationRoutes.invitationTerms}
      component={InvitationTermsOfUse}
    />
    <Route
      path="*"
      component={NotFoundContainer}
    />
  </Switch>
);

export default GuestInvitationRouter;
