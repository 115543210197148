class Constants {
    static swedenCountryId = '1d833fa0-a3c7-4b58-a0d9-1b678b2e8a3e';

    static norwayCountryId = '92222c63-38b6-489f-9d85-034dd7cb3e3b';

    static denmarkCountryId = 'a5aabd96-dfb4-4d69-8d31-41ecd731cb18';

    static finlandCountryId = 'f7309c08-b332-4dc1-8f48-36e10ff7f76d';
}

export default Constants;
