import React from 'react';
import {
  Loader
} from 'components/apollo';
import Selects from 'react-select'
import PropTypes from 'prop-types';

const propTypes = {
  cities: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  })),
  isDisabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  selectedValue: PropTypes.string.isRequired,
  fetchCities: PropTypes.func.isRequired,
};

let previousCountryKey = ''; 

const defaultProps = {
  cities: []
};

const customStyle = {
  control: (provided) => ({
    ...provided,
    border: '1px solid var(--dark-gray,#a9a9a9)',
    borderRadius: '6px',
    height: '3rem'
  })
}

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

const formatGroupLabel = data => (
  <div style={groupStyles}>
    <span>{data.label}</span>
  </div>
);

const favorites = [
'7593c90b-d2ea-4aa5-b32a-750b941a8682', // Stockholm
'6331ac09-0692-4d99-aeea-b52193ec46a2', // Göteborg
'37c72546-3192-4dae-9bd6-f7cc62753da0', // Malmö
'982d25ca-734a-49a6-a941-14295d1b5cf6', // Uppsala
'8882097e-ef1c-4c5f-a5c6-0a24478e0793', // Västerås
'1e789b59-f513-4c89-87b2-9d9002ec2296', // Helsingfors
'7fd319a1-9732-4a01-8d31-95b5869341a8', // Åbo
'757f51af-02ae-4ac9-9437-e6d3d5e0bb90', // Esbo
'961ef19a-aa78-45d7-a66a-38eddae4502c', // Tammerfors
'882186ba-9a1f-491a-8ded-bc42851895a5', // Vanda
'7f327888-9d98-45af-8203-00ba35796041', // Oslo
'fdd91e2c-0328-4b42-84ce-2f2ca74a920a', // Bergen
'86f1f641-3b49-43a7-b521-613b1a84d3ec', // Kristianstad
'd70449b3-df33-48cf-b86b-069c6e6d583d', // Fredrikstad
'4175de08-ac88-43ae-9366-b5f2d285333c', // Trondheim
'8955df1d-6db7-45a7-87a5-3fbe430a4db6', // Århus
'15a256de-1f97-4415-a644-dd9d08cb6130', // Odense
'86f36883-74dd-49c1-b5d2-1ef469fc7798', // Esbjerg
'c1c83c6c-29fc-4660-a571-d1eb60471eeb', // Ålborg
'6532acda-690a-402c-9b1b-bcefd1de987b', // Köpenhamn
'd441438c-7dcf-443a-b7d1-3ec22c7270d9', // Zurich
'0d53d016-a3d8-4f95-8ac6-f8e5f41125eb', // Geneve
'97967c12-a285-4952-a80e-e36c842dae3b', // Basel
'0c3b54e7-5f63-4af7-ac41-a250be73444f', // Lausanne
'4cbf6962-b8d9-4c42-bfb2-5e6f29cd4e8f', // Bern
];

class CitySelect extends React.Component {
  constructor() {
    super();

    this.state = {
      groupedOptions: [],
    }
  }

  groupCountries = (cities) => {
    const favoritesOptions = cities?.filter(c => favorites.includes(c.key)).map(c => ({ label: c.name, value: c.key }));
    const restOptions = cities?.filter(c => !favorites.includes(c.key)).map(c => ({ label: c.name, value: c.key }));
    this.setState({
      groupedOptions: [
        {
          label: 'Most common',
          options: favoritesOptions
        },
        {
          label: 'Cities',
          options: restOptions
        }
      ]
    })
  }

  componentDidUpdate(prevProps) {
    const { cities } = this.props;
    if (JSON.stringify(prevProps.cities) !== JSON.stringify(cities) && cities.length > 0) {
      this.groupCountries(cities);
    }
  }

  render() {
    const {
      isDisabled,
      onChange,
      selectedValue,
      cities,
      countryKey,
      fetchCities
    } = this.props;

    if(countryKey && previousCountryKey !== countryKey){
      previousCountryKey = countryKey;
      fetchCities(countryKey);
    }

    const { groupedOptions } = this.state;
  
    const selectedCity= cities.find(c => c.key === selectedValue);
    const value = {};
    if (selectedCity) {
      value.value = selectedCity.key;
      value.label = selectedCity.name;
    }
    if (groupedOptions.length > 0) {
      return (
        <Selects
          placeholder="Select a city"
          styles={customStyle}
          disabled={isDisabled}
          value={value}
          onChange={(data) => onChange({ target: { value: data.value, name: 'geoLocationKey', label: data.label } })}
          options={groupedOptions}
          formatGroupLabel={formatGroupLabel}
        />
      );
    }

    return (<Loader height={100} width={200} />);
  }
}

CitySelect.propTypes = propTypes;
CitySelect.defaultProps = defaultProps;

export default CitySelect;
