import React from 'react';
import PropTypes from 'prop-types';
import { Row, Column } from 'components/grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import RegisterColumns from '../../common/register-columns';
import './register-error.css';

const propTypes = {
  error: PropTypes.string
};

const defaultProps = {
  error: null
};

const RegisterError = (props) => {
  const {
    error
  } = props;

  if (!error) {
    return null;
  }

  return (
    <Row>
      <Column {...RegisterColumns.fullColumn}>
        <div className="register-error">
          <div className="register-error-message">
            <FontAwesomeIcon icon={faExclamationTriangle} /> {error}
          </div>
        </div>
      </Column>
    </Row>
  );
};

RegisterError.defaultProps = defaultProps;
RegisterError.propTypes = propTypes;

export default RegisterError;
