import { connect } from 'react-redux';
import RegisterError from './register-error';

const mapStateToProps = state => ({
  error: state.api.register.error,
});

export default connect(
  mapStateToProps,
  null
)(RegisterError);
