import { connect } from 'react-redux';
import RegisterPartnerSuccess from './register-partner-success';

// const testData = {
//   personId:"1d4f33d7-e152-48b3-a694-8167521aca75",
//   mdPersonId:"ink474",
//   relationId:"00000000-0000-0000-0000-000000000000",
//   isWaitingRelation:true,
//   isActiveSubcon: false,
//   userType: 'Subcon',
//   alreadyExists:true,
//   anonymizedEmail:"t********3@test.com",
//   currentEndDate:'2024-04-04',
// }

const mapStateToProps = state => ({
  portalUrl: state.appsettings.andromeda.portalUrl,
  companyName: state.appsettings.company.name,
  status: state.api.register.data,
});

export default connect(
  mapStateToProps,
  null
)(RegisterPartnerSuccess);
