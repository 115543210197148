import React from 'react';
import PropTypes from 'prop-types';
import './basic-alert.css';
import classNames from 'classnames';

const BasicAlert = ({ header, message, bottomSpacing }) => {
  const classes = classNames({
    'basic-alert': true,
    'basic-alert--bottom-spacing': bottomSpacing
  });

  return (
    <div className={classes}>
      <h5>{header}</h5>
      <p>{message}</p>
    </div>
  );
};

BasicAlert.propTypes = {
  header: PropTypes.string,
  message: PropTypes.string,
  bottomSpacing: PropTypes.bool
};

BasicAlert.defaultProps = {
  header: 'Incomplete form',
  message: 'Please fill out the required fields above.',
  bottomSpacing: false
};

export default BasicAlert;
