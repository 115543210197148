import { connect } from 'react-redux';
import { createLoadingSelector } from 'redux/selectors';
import RegisterFooter from './register-footer';

const saving = createLoadingSelector(['REGISTER_PARTNER', 'REGISTER_EMERITUS', 'REGISTER_FUTURE']);

const mapStateToProps = state => ({
  saving: saving(state),
});

export default connect(
  mapStateToProps,
  null
)(RegisterFooter);
