import { actionTypes } from 'redux/actions/appsettings';

const appsettingsReducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.SET_APPSETTINGS:
      return { ...action.payload };
    default:
      return state;
  }
};

export default appsettingsReducer;
