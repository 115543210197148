import { connect } from 'react-redux';
import { registerFuture } from 'redux/services/api/register';
import RegisterFuture from './register-future';
import { fetchCities } from 'redux/services/api/city';

const mapStateToProps = (state) => ({
  success: state.api.register.success,
  azureAd: true,
  cities: state.api.city.cities,
});

const mapDispatchToProps = (dispatch) => ({
  register: (formData) => dispatch(registerFuture(formData)),
  fetchCities: (countryKey) => dispatch(fetchCities(countryKey)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterFuture);
