import React from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Container,
  Navbar,
  NavbarBrand
} from 'components/apollo';
import { Row, Column } from 'components/grid';
import classNames from 'classnames';
import BasicAlert from 'components/common/basic-alert';
import ReactGA from 'react-ga';
import RegisterPersonalInfoForm from '../../components/register-personal-info-form';
import RegisterPartnerInfoForm from '../../components/register-partner-info-form';
import RegisterAccountInfoForm from '../../components/register-account-info-form';
import RegisterFooter from '../../components/register-footer';
import RegisterSuccess from '../../components/register-partner-success';
import RegisterError from '../../components/register-error';
import Constants from '../../common/constans';
import Validation from '../../common/validation';
import './register-partner.css';
import PAGES from '../../utils/ga-constants';
import iconImage from './AFRY-Logotype-Horizontal_White.svg'

const propTypes = {
  register: PropTypes.func.isRequired,
  success: PropTypes.bool.isRequired,
  countries: PropTypes.arrayOf(PropTypes.object).isRequired,
  fetchCountries: PropTypes.func.isRequired,
  azureAd: PropTypes.bool.isRequired,
};

class RegisterPartner extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      personal: {
        birthday: '',
        homeCountryId: '',
        selectedCivic: Validation.civicLastDigits.SE.locale,
        selectedCountry: Validation.civicLastDigits.SE.locale,
        selectedLanguage: 'sv',
        header: 'Partner',
        phoneMobile: '',
        streetAddress: '',
        zipCode: '',
        careOf: '',
        phoneNumberValid: 'required'
      },
      partner: {
        communicationResponsible: true
      },
      hasFetchedCountries: false,
      account: {},
      privacyStatement: false,
      captchaCompleted: false,
      currentStep: 0,
      showErrors: false,
      virtualPage: {
        info: undefined,
        account: undefined
      }
    };

    this.steps = [
      RegisterPersonalInfoForm,
      RegisterPartnerInfoForm,
      RegisterAccountInfoForm
    ];
  }

  componentDidMount() {
    const {
      fetchCountries,
      countries
    } = this.props;
    const { languageCode } = this.props.params;

    this.setState({
      account: {
        languageCode
      }
    });
    if (countries.length < 1) {
      fetchCountries();
    }
  }

  handleBirthdayChange = (obj) => {
    const {
      birthday,
      civicBirthDate,
    } = obj;

    this.setState(prevState => ({
      personal: {
        ...prevState.personal,
        birthday,
        civicBirthDate,
      }
    }));
  }

  mapCountryCodeToCivic = (countryCode) => {
    switch (countryCode) {
      case 'SE':
        return 'SE';
      case 'DK':
        return 'DK';
      case 'FI':
        return 'FI';
      case 'NK':
        return 'NK';
      default:
        return 'otherCountry';
    }
  }

  handleInputChange = (name, value) => {
    const propertyName = name;
    const {
      currentStep
    } = this.state;

    if (currentStep === 0) {
      const {
        personal
      } = this.state;
      if (name === 'selectedCivic') {
        const {
          birthday
        } = personal;

        if (value === 'SE') {
          this.setState(prevState => ({
            personal: {
              ...prevState.personal,
              homeCountryId: Constants.swedenCountryId,
              civicBirthDate: Validation.civicLastDigits[value].dateFormat(birthday),
              [propertyName]: value,
              selectedCivic: value,
              selectedCountry: value,
            }
          }));
        } else if (value === 'NO') {
          this.setState(prevState => ({
            personal: {
              ...prevState.personal,
              homeCountryId: Constants.norwayCountryId,
              civicBirthDate: Validation.civicLastDigits[value].dateFormat(birthday),
              [propertyName]: value,
              selectedCivic: value,
              selectedCountry: value,
            }
          }));
        } else if (value === 'DK') {
          this.setState(prevState => ({
            personal: {
              ...prevState.personal,
              homeCountryId: Constants.denmarkCountryId,
              civicBirthDate: Validation.civicLastDigits[value].dateFormat(birthday),
              [propertyName]: value,
              selectedCivic: value,
              selectedCountry: value,
            }
          }));
        } else if (value === 'FI') {
          this.setState(prevState => ({
            personal: {
              ...prevState.personal,
              homeCountryId: Constants.finlandCountryId,
              civicBirthDate: Validation.civicLastDigits[value].dateFormat(birthday),
              [propertyName]: value,
              selectedCivic: value,
              selectedCountry: value,
            }
          }));
        } else if (value === 'otherCountry') {
          this.setState(prevState => ({
            personal: {
              ...prevState.personal,
              homeCountryId: '',
              civicBirthDate: Validation.civicLastDigits[value].dateFormat(birthday),
              [propertyName]: value,
              selectedCivic: 'otherCountry',
              selectedCountry: '',
            }
          }));
        }
      } else if (name === 'phoneNumberValid') {
        if (value !== personal.phoneNumberValid) {
          this.setState(prevState => ({
            personal: { ...prevState.personal, phoneNumberValid: value }
          }));
        }
      } else if (propertyName === 'phoneMobile') {
        this.setState(prevState => ({ personal: { ...prevState.personal, phoneMobile: value } }));
      } else {
        this.setState(prevState => ({
          personal: { ...prevState.personal, [propertyName]: value }
        }));
      }
    } else if (currentStep === 1) {
      const {
        partner
      } = this.state;

      if (name === 'companyNotStarted' && value) {
        const {
          defaultOrgNumber,
          defaultCompanyName
        } = partner;

        this.setState(prevState => ({
          partner: {
            ...prevState.partner,
            companyName: defaultCompanyName,
            organizationNumber: defaultOrgNumber
          }
        }));
      } else if (name === 'communicationResponsible' && value) {
        this.setState(prevState => ({
          partner: {
            ...prevState.partner,
            communicationResponsible: value,
            salesManager: '',
            phoneMobileSalesManager: '',
            emailSalesManager: ''
          }
        }));
      } else {
        this.setState(prevState => ({
          partner: { ...prevState.partner, [propertyName]: value }
        }));
      }
    } else if (currentStep === 2) {
      this.setState(prevState => ({
        account: { ...prevState.account, [propertyName]: value }
      }));
    }
  }

  handleStepValid = (valid) => {
    const {
      currentStep
    } = this.state;

    if (currentStep === 0) {
      this.setState({
        personalValid: valid
      });
    } else if (currentStep === 1) {
      const { partner } = this.state;
      this.setState({
        partnerValid: valid,
        partner: { ...partner, valid }
      });
    } else if (currentStep === 2) {
      this.setState({
        accountValid: valid
      });
    }
  }

  handleNextClick = () => {
    const {
      currentStep,
      partnerValid
    } = this.state;

    const {
      personal
    } = this.state;

    const {
      firstName,
      lastName,
      birthday,
      phoneNumberValid,
      zipCode,
      streetAddress,
      homeCountryId,
      linkedin,
      civicBirthDate,
      civicLastDigits,
      selectedCivic,
      geoLocationKey,
    } = personal;

    const personValid = Validation.Person.firstName(firstName)
      && Validation.Person.lastName(lastName)
      && Validation.Person.birthDay(birthday)
      && phoneNumberValid === 'Valid'
      && Validation.Person.geoLocationKey(geoLocationKey)
      && Validation.Person.zipCode(zipCode)
      && Validation.Person.streetAddress(streetAddress)
      && Validation.Person.country(homeCountryId)
      && Validation.Person.linkedin(linkedin)
      && Validation.Person.civicRegNumberLast(civicLastDigits, selectedCivic)
      && Validation.Person.civicRegNumberCheckSum(civicBirthDate, civicLastDigits, selectedCivic);

    if (currentStep === 0 && !personValid) {
      this.setState({
        showErrors: true
      });
    } else if (currentStep === 1 && !partnerValid) {
      this.setState({
        showErrors: true
      });
    } else {
      const {
        partner
      } = this.state;

      const defaultOrgNumber = civicLastDigits ? `${civicBirthDate}-${civicLastDigits}` : civicBirthDate;
      const defaultCompanyName = `${firstName} ${lastName}`;

      this.setState({
        currentStep: currentStep + 1,
        partner: { ...partner, defaultOrgNumber, defaultCompanyName },
        showErrors: false
      }, this.setVirtualPage);
    }
  }

  setVirtualPage = () => {
    const {
      currentStep,
      virtualPage: {
        info,
        account
      }
    } = this.state;

    switch (currentStep) {
      case 1:
        if (info === undefined) {
          ReactGA.ga('send', 'pageview', `${window.location.pathname}${PAGES.INFO}`);
        }
        this.setState(prevState => ({
          virtualPage: {
            ...prevState.virtualPage,
            info: true
          }
        }));
        break;
      case 2:
        if (account === undefined) {
          ReactGA.ga('send', 'pageview', `${window.location.pathname}${PAGES.ACCOUNT}`);
        }
        this.setState(prevState => ({
          virtualPage: {
            ...prevState.virtualPage,
            account: true
          }
        }));
        break;
      default:
        break;
    }
  };

  handleBackClick = () => {
    const {
      currentStep
    } = this.state;

    this.setState({
      currentStep: currentStep - 1,
      showErrors: false
    });
  }

  handleSubmit = () => {
    const {
      accountValid
    } = this.state;
    if (!accountValid) {
      this.setState({
        showErrors: true
      });
    } else {
      const { register } = this.props;
      const { personal, partner } = this.state;
      const { phoneMobile } = personal;
      const { phoneMobileSalesManager, communicationResponsible } = partner;
      register({ ...this.state, personal: { ...personal, phoneMobile: `+${phoneMobile}` }, partner: { ...partner, phoneMobileSalesManager: communicationResponsible ? '' : `+${phoneMobileSalesManager}` } });
    }
  }

  render() {
    const {
      currentStep,
      personal,
      partner,
      account,
      showErrors,
    } = this.state;

    const {
      success,
      azureAd
    } = this.props;

    const showBack = currentStep !== 0;
    const showNext = currentStep < this.steps.length - 1;

    const Step = this.steps[currentStep];
    const data = [personal, partner, account];

    const contentCols = {
      mobile: {
        size: 12,
        offset: 0
      },
      tablet: {
        size: 12,
        offset: 0
      },
      desktop: {
        size: 8,
        offset: 2
      },
      largeDesktop: {
        size: 8,
        offset: 2
      }
    };

    const classes = classNames('register-partner');
    const contentClasses = classNames('register-partner-content');
    return (
      <div className={classes}>
        <Navbar
          dark
          fixed="top"
          expand="large"
        >
          <Container>
            <NavbarBrand icon={iconImage} />
          </Container>
        </Navbar>
        <Container className={'register-mobile-container'}>
          <Row>
            <Column
              mobile={contentCols.mobile}
              tablet={contentCols.tablet}
              desktop={contentCols.desktop}
              largeDesktop={contentCols.largeDesktop}
            >
              <div className={contentClasses}>
                {success && (
                  <RegisterSuccess />
                )}
                {!success && (
                  <Step
                    data={data[currentStep]}
                    onChange={this.handleInputChange}
                    onBirthDayChange={this.handleBirthdayChange}
                    onValidChange={this.handleStepValid}
                    showErrors={showErrors}
                    registerPassword={!azureAd}
                  />
                )}
                <RegisterError />
              </div>
            </Column>
          </Row>
          {
            showErrors && (
              <Row>
                <Column mobile={contentCols.mobile} tablet="hidden" desktop="hidden" largeDesktop="hidden">
                  <BasicAlert />
                </Column>
              </Row>
            )
          }
        </Container>
        {!success && (
          <RegisterFooter
            showNext={showNext}
            showBack={showBack}
            onNext={this.handleNextClick}
            onBack={this.handleBackClick}
            onSubmit={this.handleSubmit}
            partnerInfo
          />
        )}
      </div>
    );
  }
}

RegisterPartner.propTypes = propTypes;

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

export default withParams(RegisterPartner);
