export const actionTypes = {
  GET_CITIES_REQUEST: 'GET_CITIES_REQUEST',
  GET_CITIES_SUCCESS: 'GET_CITIES_SUCCESS',
  GET_CITIES_FAILURE: 'GET_CITIES_FAILURE',
};

export const getCitiesRequest = () => ({
  type: actionTypes.GET_CITIES_REQUEST,
});

export const getCitiesSuccess = data => ({
  type: actionTypes.GET_CITIES_SUCCESS,
  payload: data
});

export const getCitiesFailure = error => ({
  type: actionTypes.GET_CITIES_FAILURE,
  payload: error
});
