import axios from 'axios';

const BASE_URL = process.env.REACT_APP_ANDROMEDA_REGISTER_BASEURL;

const config = {
  headers: {
    Accept: 'application/json',
    Pragma: 'no-cache',
    'Cache-Control': 'no-cache'
  }
};

export const fetchRegion = (country, city) => axios.get(`${BASE_URL}/location/region?country=${country}&city=${city}`, config);