import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from 'components/apollo';
import './alert-modal.css';
import classNames from 'classnames';

const propTypes = {
  className: PropTypes.string,
  closeButtonText: PropTypes.string,
  hideModal: PropTypes.func,
  isLoading: PropTypes.bool,
  message: PropTypes.node,
  onClose: PropTypes.func,
  title: PropTypes.string,
};

const defaultProps = {
  className: '',
  closeButtonText: 'Close',
  hideModal: undefined,
  isLoading: false,
  message: undefined,
  onClose: undefined,
  title: undefined,
};

const AlertModal = (props) => {
  const {
    title,
    message,
    closeButtonText,
    onClose,
    hideModal,
    isLoading,
    className,
  } = props;

  const [open, setOpen] = useState(true);

  const handleClose = (e) => {
    if (onClose) onClose(e);
    setOpen(false);
  };

  const modalClasses = classNames(
    className,
    'alert-modal'
  );

  return (
    <Modal
      className={modalClasses}
      contentClassName="alert-modal-content"
      dialogClassName="alert-modal-dialog"
      open={open}
      onClosed={() => hideModal()}
    >
      <ModalHeader className="alert-modal-top">{title}</ModalHeader>
      <ModalBody className="alert-modal-body">{message}</ModalBody>
      <ModalFooter className="alert-modal-footer">
        <Button color='primary' onClick={handleClose} loading={isLoading}>
          {closeButtonText}
        </Button>
      </ModalFooter>
    </Modal>
  );
};


AlertModal.propTypes = propTypes;
AlertModal.defaultProps = defaultProps;

export default AlertModal;
