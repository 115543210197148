import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NavbarBrand } from 'components/apollo';
import styles from './af-navbar-brand.module.css';
import iconImage from './AFRY-Logotype-Horizontal_White.svg'

const propTypes = {
  className: PropTypes.string,
};

const defaultProps = {
  className: undefined,
};

const AFNavbarBrand = (props) => {
  const {
    className,
    staticContext,
    ...other
  } = props;

  const classes = classNames(
    className,
    styles['af-navbar-brand']
  );

  return (
    <NavbarBrand className={classes} icon={iconImage} {...other} />
  );
};

AFNavbarBrand.propTypes = propTypes;
AFNavbarBrand.defaultProps = defaultProps;

export default AFNavbarBrand;
