
// eslint-disable-next-line import/no-anonymous-default-export
export default (state = {}, action) => {
  const { type, payload } = action;
  const matches = /(.*)_(REQUEST|FAILURE|CLEAR|CLEAR_ERROR)$/.exec(type);

  // not a *_REQUEST / *_FAILURE actions, so we ignore them
  if (!matches) return state;

  const [, requestName, requestState] = matches;

  return {
    ...state,
    // Store errorMessage
    // e.g. stores errorMessage when receiving GET_TODOS_FAILURE
    //      else clear errorMessage when receiving GET_TODOS_REQUEST
    //      or GET_TODOS_CLEAR_ERROR
    [requestName]: requestState === 'FAILURE' ? payload.message : '',
  };
};
