import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactPhoneNumberInput from 'react-phone-number-input';
import './phone-input.css';

const propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
};

const defaultProps = {
  id: undefined,
  name: undefined,
  placeholder: '',
  value: undefined,
  onBlur: undefined,
  disabled: false,
};

const PhoneInput = (props) => {
  const {
    id,
    name,
    placeholder,
    value,
    onChange,
    onBlur,
    disabled,
  } = props;

  const classes = classNames(
    'phone-input',
    disabled && 'disabled'
  );

  return (
    <div className={classes}>
      <ReactPhoneNumberInput
        id={id}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
      />
    </div>
  );
};

PhoneInput.propTypes = propTypes;
PhoneInput.defaultProps = defaultProps;

export default PhoneInput;
