import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Navbar } from 'components/apollo';
import BrandIcon from 'components/navigation/main-navigation/common/brand-icon/brand-icon';
import './terms-of-use.css';

const InvitationTermsOfUseContent = () => (
  <div>
    <h4>1. Terms of Use</h4>
    <p>
      These terms of use (&quot;<b>Terms of Use</b>&quot;) govern the use of the
      websites www.portal.afry.com and afonline.sharepoint.com (the &quot;
      <b>Websites</b>&quot;) by any person who has registered and established a
      username to do so (the &quot;<b>User</b>&quot;). The Websites is provided
      by AFRY Group. The Websites is a business-to-business service and only
      meant to be used by employees of AFRY’s clients.
    </p>
    <h4>2. Use of the Website</h4>
    <p>
      Use of the Websites constitutes the User&apos;s acceptance of, and
      agreement to be bound by, these Terms of Use. Any User who does not agree
      to these Terms of Use must immediately cease using the Websites.
    </p>
    <p>
      AFRY reserves the right to amend these Terms of Use from time to time by
      posting changes on the Websites, and the User acknowledges that its
      continued use of the Websites signifies acceptance of any such revised
      Terms of Use.
    </p>
    <h4>3. The Websites</h4>
    <p>
      The Websites are used to share information and documents between AFRY and
      its clients. The Websites provides installable apps for example SharePoint
      Online for storage, documentation and communication for AFRY projects.
    </p>
    <p>
      The User is responsible for obtaining, installing, maintaining and
      utilising at its own expense, compatible computer equipment and
      communications devices required to utilise the Websites.
    </p>
    <p>
      If the User has problems with the log-in or use of the Websites, the User
      should contact its employer’s IT department or similar if the User also
      has other O365 access problems. If the User’s access problems relate only
      to the Websites, the User should contact the AFRY contact person working
      in the related assignment who will forward the information to relevant
      parties.
    </p>
    <h4>4. Registration, usernames and passwords</h4>
    <p>
      Log-in credentials are personal to each User. The User undertakes not to
      disclose or offer to disclose the User’s log-in credentials to any other
      person (either body corporate or natural), including (for the avoidance of
      doubt) to any other person within the same organisation, and acknowledges
      that any breach of security resulting in the use or attempted use of
      his/her log-in credentials by any other person constitutes a material
      breach by the User of the Terms of Use.
    </p>
    <h4>5. No reliance</h4>
    <p>
      AFRY makes no representation or warranty that the information or opinions
      contained in the Websites are accurate, reliable, up-to-date or complete.
      AFRY cannot guarantee the accuracy of the underlying data or any
      projections based thereon.
    </p>
    <h4>6. Prohibition of Use</h4>
    <p>
      The User agrees to use the Websites, including all features and
      functionalities associated therewith, in accordance with all applicable
      laws, rules and regulations, or other restrictions on use of the service
      or content. The User agrees not to reproduce, distribute, modify, publish,
      license, create derivative works from, offer for sale, or use (except as
      explicitly authorized in these Terms of Use) the Websites. The User also
      agrees not to: use any robot, spider, scraper or other automated means to
      access the Websites. In addition, the User agree not to upload, post,
      e-mail or otherwise send or transmit any material designed to interrupt,
      destroy or limit the functionality of any computer software or hardware or
      telecommunications equipment associated with the Websites, including any
      software viruses or any other computer code, files or programs.
    </p>
    <p>
      AFRY may terminate or restrict the use of the Websites if the User
      violates these Terms of Use or are engaged in illegal or fraudulent use of
      the service.
    </p>
    <h4>7. Data protection</h4>
    <p>
      The User recognises that in order to provide the Websites, AFRY needs to
      maintain basic contact and other details concerning the User (e.g. first
      name, last name, email address, company name and telephone number). AFRY
      undertakes to comply with the applicable data privacy laws. More details
      can be found in{' '}
      <a target="blank" href="https://afry.com/en/privacy-policy">
        AFRY’s Privacy Notice
      </a>
    </p>
    <h4>8. Term and termination</h4>
    <p>
      The Websites will be provided for a period agreed between the Client and
      AFRY. AFRY is entitled to terminate the use of the Websites any time
      without further notice.
    </p>
    <h4>9. Governing law and Disputes</h4>
    <p>
      The Terms and Conditions shall be governed by and construed under the laws
      of Sweden excluding its conflict of law rules. Any disputes shall be
      finally settled by arbitration in accordance with the Arbitration Rules of
      the Arbitration Institute of the Stockholm Chamber of Commerce. The place
      of arbitration shall be Stockholm, Sweden. The language of the arbitration
      proceedings shall be English unless otherwise agreed between the parties.
    </p>
  </div>
);

export const InvitationTermsOfUseModal = () => (
  <div className="terms-of-use-modal">
    <InvitationTermsOfUseContent />
  </div>
);

const InvitationTermsOfUsePage = () => (
  <React.Fragment>
    <Helmet title="AFRY" />
    <Navbar dark fixed="top" expand>
      <Container>
        <BrandIcon homeTarget="" currentLocation=""/>
      </Container>
    </Navbar>
    <Container className="terms-of-use-page">
      <InvitationTermsOfUseContent />
    </Container>
  </React.Fragment>
);

export default InvitationTermsOfUsePage;
