import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Navbar, Loader } from 'components/apollo';
import AFNavbarBrand from 'components/common/af-navbar-brand';
import { Row, Column } from 'components/grid';
import './confirm-active.css';

const contentCols = {
  mobile: {
    size: 8,
    offset: 2,
  },
  tablet: {
    size: 8,
    offset: 2,
  },
  desktop: {
    size: 8,
    offset: 2,
  },
  largeDesktop: {
    size: 8,
    offset: 2,
  },
};

const callConfirmActive = async (personId) => {
  const response = await fetch(
    `${process.env.REACT_APP_ANDROMEDA_REGISTER_BASEURL}/ConfirmActive/${personId}`,
    {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
    }
  );

  if (!response.ok) {
    throw new Error('Failed calling ConfirmActive');
  }

  return await response.json();
};

function ConfirmActive() {
  const { personId } = useParams();

  const [error, setError] = useState(false);
  const [personKey, setPersonKey] = useState();

  useEffect(() => {
    (async () => {
      try {
        const response = await callConfirmActive(personId);
        setPersonKey(response);
        setError(false);
      } catch (e) {
        setError(true);
      }
    })();
  }, [personId]);

  if (personKey) {
    window.location = `${process.env.REACT_APP_ANDROMEDA_PORTAL_URL}en/PersonProfile/${personKey}`;
  }

  return (
    <div className="confirm-active">
      <Navbar dark fixed="top" expand="large">
        <Container>
          <AFNavbarBrand />
        </Container>
      </Navbar>
      <Container>
        <Row>
          <Column {...contentCols}>
            <div className="confirm-active-content">
              {/* <h2>{header}</h2> */}
              <p>
                {error ? (
                  'Something went wrong when activating your account. Please contact support@afry.com.'
                ) : (
                  <Loader />
                )}
              </p>
            </div>
          </Column>
        </Row>
      </Container>
    </div>
  );
}

export default ConfirmActive;
