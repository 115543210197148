import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './layout.module.css';

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

const defaultProps = {
  className: undefined
};

const Layout = (props) => {
  const {
    className,
    children,
  } = props;

  const classes = classNames(
    className,
    styles.layout,
  );

  return (
    <div className={classes}>
      {children}
    </div>
  );
};

Layout.propTypes = propTypes;
Layout.defaultProps = defaultProps;

export default Layout;
