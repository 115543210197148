import { connect } from 'react-redux';
import RegisterPartnerInfoForm from './register-partner-info-form';

const mapStateToProps = state => ({
  companyNameSetting: state.appsettings.company.name,
});

export default connect(
  mapStateToProps,
  null,
)(RegisterPartnerInfoForm);
