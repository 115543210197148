import { connect } from 'react-redux';
import GoogleRecaptcha from './google-recaptcha';

const mapStateToProps = state => ({
  siteKey: state.appsettings.recaptcha.siteKey,
});

export default connect(
  mapStateToProps,
  null
)(GoogleRecaptcha);
