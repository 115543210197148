import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import classNames from 'classnames';
import { Button, Container } from 'components/apollo';
import { LayoutContainer } from '../layout';
import styles from './not-found.module.css';

const propTypes = {
  goBack: PropTypes.func.isRequired,
  replace: PropTypes.func.isRequired,
};

class NotFound extends React.Component {
  constructor(props) {
    super(props);
    this.handleGoBack = this.handleGoBack.bind(this);
    this.handleReplace = this.handleReplace.bind(this);
  }

  handleGoBack() {
    const { goBack } = this.props;
    goBack();
  }

  handleReplace(url) {
    const { replace } = this.props;
    replace(url);
  }

  render() {
    const classes = classNames(styles['not-found']);
    const title = "This page isn't available";
    const message = 'The link you followed may be broken, or the page may have been removed.';

    return (
      <LayoutContainer>
        <Container>
          <Helmet title="Page Not Found" />
          <div className={classes}>
            <h4 className={classNames(styles['not-found-title'])}>
              {title}
            </h4>
            <p className={classNames(styles['not-found-message'])}>
              {message}
            </p>
            <Button tag="a" color="link" onClick={() => this.handleGoBack()}>Go to Previous Page</Button>
            <Button tag="a" color="link" onClick={() => this.handleReplace('/')}>Go to Home Page</Button>
          </div>
        </Container>
      </LayoutContainer>
    );
  }
}

NotFound.propTypes = propTypes;

export default NotFound;
