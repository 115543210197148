import React from 'react';
import { Route, Switch } from 'react-router';
import { NotFoundContainer } from 'views/shared/not-found';
import ConfirmActive from '../views/confirm-active';
import { appRoutes } from '../../../app-routes';

const parentRoutePath = appRoutes.confirmActive;

const verificationRoutes = {
  email: `${parentRoutePath}/:personId`,
};

const ConfirmActiveRouter = () => (
  <Switch>
    <Route path={verificationRoutes.email} component={ConfirmActive} />
    <Route path="*" component={NotFoundContainer} />
  </Switch>
);

export default ConfirmActiveRouter;
