const InvitationResponseStatus = {
    Default: 0,
    Pending: 1,
    Accepted: 2,
    Rejected: 3,
    Joined: 4,
    Removed: 5,
    Leaving: 6,
    Expired: 7
  };
  
  export default InvitationResponseStatus;
  