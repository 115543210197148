import { connect } from 'react-redux';
import { goBack, replace } from 'redux-first-history';
import NotFound from './not-found';

const mapDispatchToProps = dispatch => ({
  goBack: () => dispatch(goBack()),
  replace: path => dispatch(replace(path)),
});

export default connect(
  null,
  mapDispatchToProps,
)(NotFound);
