import React from 'react';
import { Route, Switch } from 'react-router';
import { NotFoundContainer } from 'views/shared/not-found';
import VerificationEmail from '../views/verification-email';
import { appRoutes } from '../../../app-routes';

const parentRoutePath = appRoutes.verification;

const verificationRoutes = {
  email: `${parentRoutePath}/email/:token?`,
};

const VerificationRouter = () => (
  <Switch>
    <Route
      path={verificationRoutes.email}
      component={VerificationEmail}
    />
    <Route
      path="*"
      component={NotFoundContainer}
    />
  </Switch>
);

export default VerificationRouter;
