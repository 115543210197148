import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import './styles.css';

import { App } from './app';
import faSetup from './utilities/font-awesome-setup';

import { IntlProvider } from 'react-intl';

import { store, history } from './store';
import { Router } from 'react-router-dom';
import ScrollToTop from './components/router/scroll-to-top';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { setAppsettings } from './redux/actions/appsettings';
import { initializeReactGA } from 'utilities/ReactGAHelper';
import { reduxAppsettings } from './redux-appsettings';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

faSetup();

initializeReactGA(
  reduxAppsettings?.googleAnalytics?.trackingCode,
  reduxAppsettings?.googleAnalytics?.debug
);

store.dispatch(setAppsettings(reduxAppsettings));

const root = createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <Router history={history}>
      <QueryClientProvider client={queryClient}>
        <IntlProvider locale={navigator.language}>
          <ScrollToTop />
          <App />
        </IntlProvider>
      </QueryClientProvider>
    </Router>
  </Provider>
  // </React.StrictMode>
);
