import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Avatar.css';

const propTypes = {
  className: PropTypes.string,
  initials: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.oneOf(['x-small', 'small', 'medium', 'large', 'x-large']),
  src: PropTypes.string,
};

const defaultProps = {
  className: undefined,
  initials: undefined,
  name: undefined,
  size: 'medium',
  src: undefined,
};

function Avatar(props) {
  const [error, setError] = useState(false);

  let { initials } = props;
  const { className, name, size, tag: Tag, ...other } = props;

  const classes = classNames(
    className,
    'apollo-avatar',
    `apollo-avatar-${size}`,
  );

  if (name) {
    const parts = name.split(' ');
    let result = '';
    for (let i = 0; i < parts.length; i += 1) {
      result += parts[i].substr(0, 1).toUpperCase();
    }

    initials = result.slice(0, 2);
  }

  if (error) {
    return (
      <div className={classes}>
        <span>{initials}</span>
      </div>
    );
  }

  return <img alt={name} {...other} className={classes} onError={() => setError(true)} />;
}

Avatar.propTypes = propTypes;
Avatar.defaultProps = defaultProps;

export default Avatar;
